import React from "react";
import { Badge, ListGroup } from "react-bootstrap";
import { FaChevronRight, FaFileAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import * as api from "../../api/api";
import * as missionApi from "../../api/missionApi";
import MissionStatusMessage from "../../components/MissionStatusMessage";
import MissionActivityBadge from "../MissionActivityBadge";
import MeasurementInfoBadge from "../MeasurementInfo"

export function MissionMeasurementBadge(props) {

    return (
        <MeasurementInfoBadge mission={props.mission} small />
    );
}

function MissionResultsBadge({ mission, className }) {
    const [results, setResults] = React.useState([]);
    const [downloadableResults, setDownloadableResults] = React.useState([]);

    function getAppResults(app, results) {
        return results.filter(result => result.app.id === app.id);
    }

    React.useEffect(() => {
        return api.subscribeResults(mission.organizationId, mission.locationId, mission.id, setResults);
    }, [mission]);

    React.useEffect(() => {
        const newDownloadableResults = missionApi.getMissionApps(mission).flatMap(app => {
            const appResults = getAppResults(app, results);
            return appResults.filter(result => result.fileName);
        });
        setDownloadableResults(newDownloadableResults);
    }, [mission, results]);


    const handleClick = (event) => {
        if (downloadableResults.length === 1) {
            event.preventDefault();
            event.stopPropagation();
            api.downloadResult(mission.organizationId, mission.locationId, mission.id, downloadableResults[0].id, downloadableResults[0].fileName);
        }
    };

    return (
        <span>
            {downloadableResults.length > 0 && (
                <Badge
                    pill
                    bg="success"
                    className={className}
                    onClick={handleClick}
                >
                    <FaFileAlt className="me-1" style={{ marginTop: "-2px" }} />
                    {downloadableResults.length}
                </Badge>
            )}
        </span>
    );
}

export default function MissionListItem(props) {
    return (
        <ListGroup.Item
            className="px-4 py-2 d-flex justify-content-between align-items-start"
            key={props.key}
            as={Link}
            to={props.to}
            action
        >
            <div>
                <MissionStatusMessage
                    mission={props.mission}
                >
                    {props.children}
                </MissionStatusMessage>
            </div>
            <div>
                {props.showActivity && (
                    <MissionActivityBadge
                        className="me-1"
                        mission={props.mission}
                    />
                )}
                {<MissionResultsBadge
                    className='me-1'
                    mission={props.mission}
                />}
                {props.showMeasurements && (
                    <MissionMeasurementBadge
                        className='me-1'
                        mission={props.mission}
                    />
                )}
                <FaChevronRight style={{ color: "#b9b9b9" }} />
            </div>
        </ListGroup.Item>
    );
}
