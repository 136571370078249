import { commandType } from "./Attributes";

export const Skeleton = {
    type: commandType,
    settings: {
        startKap: "",
        startLeg: "",
        endKap: "",
        endLeg: "",
    },
};