import React from "react";
import { FaTimes } from "react-icons/fa";
import { Button, Modal } from "react-bootstrap";

import * as api from "../../api/api";

export default function AbortButton(props) {
    const [showAbortModal, setShowAbortModal] = React.useState(false);

    function abortMission() {
        setShowAbortModal(false);
        api.storeAbortMissionEvent(props?.mission?.organizationId, props?.mission?.locationId, props?.mission?.id);
        api.cleanUpMissionHandler(props?.mission?.organizationId, props?.mission?.locationId, props?.mission?.id);
    }

    return (
        <>
            <Modal show={showAbortModal} onHide={() => setShowAbortModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Abort Mission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Abort{' '}mission{' '}now?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="outline-secondary"
                        onClick={() => setShowAbortModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={abortMission}
                    >
                        <FaTimes
                            className="me-2"
                            style={{ marginTop: "-2px" }}
                        />
                        Abort
                    </Button>
                </Modal.Footer>
            </Modal>
            {props?.mission?.states?.collecting === "running" && (<Button
                variant="danger"
                className={props.className}
                size={props.size}
                onClick={() => setShowAbortModal(true)}
            >
                <FaTimes className="me-2" />
                Abort
            </Button >
            )
            }
        </>


    );
}