import React from "react";
import { Container, Graphics } from "@inlet/react-pixi";
import { useMapContext } from '../MapContext';
import * as PIXI from 'pixi.js';

export const Measurement = React.memo(function Measurement({ x, y, onClick }) {
    const { worldToMap } = useMapContext();   

    const draw = React.useCallback(
        (graphic) => {
            graphic.clear();
            graphic.lineStyle(1, 0xff0000);
            graphic.drawCircle(...Object.values(worldToMap({ x, y })), /*diameter=*/1);
        },
        [x, y, worldToMap]
    );

    return (
        <Container>
            <Graphics
                draw={draw}
                interactive={!!onClick}
                buttonMode={!!onClick}
                pointertap={onClick}
                hitArea={new PIXI.Circle(...Object.values(worldToMap({ x, y })), 1.5)}
            />
        </Container>
    );
});