// (mission) EVENTS
// part of mission document
// const MISSION_INITIATED = 'FLIGHT_INITIATED';

// // (measurement) EVENTS // TODO
// const MEASUREMENT_INITIATED = INITIATED;
// const MEASUREMENT_CAPTURED = 'CAPTURED';
// const MEASUREMENT_UPLOADED = 'UPLOADED';
// const MEASUREMENT_TRANSFERRED = 'TRANSFERRED';

// (mission step 1) COLLECTING EVENTS
// (flight) EVENTS
// a flight is one way of collecting
const COLLECTING_STARTED = 'FLIGHT_STARTED'; // TODO : rename to name
const COLLECTING_SUCCEEDED = 'FLIGHT_SUCCEEDED'; // TODO : rename to name
const COLLECTING_FAILED = 'FLIGHT_FAILED'; // TODO : rename to name

// (mission step 2) UPLOADING EVENTS
// part of flight & measurement document
const UPLOADING_STARTED = 'FLIGHT_SUCCEEDED'; // TODO : rename to name
const UPLOADING_SUCCEEDED = 'FLIGHT_UPLOADED'; // TODO : rename to name
const UPLOADING_FAILED = 'FLIGHT_FAILED'; // TODO : rename to name

// // TODO : emit these events throughout the system ...
// part of mission & measurement document
// // (mission step 3) TRANSFERRING EVENTS
// const TRANSFERRING_STARTED = 'TRANSFERRING_STARTED';
// const TRANSFERRING_SUCCEEDED = 'TRANSFERRING_SUCCEEDED';
// const TRANSFERRING_FAILED = 'TRANSFERRING_FAILED';

// (mission step 4) PROCESSING EVENTS
const PROCESSING_STARTED = 'APP_STARTED'; // TODO : rename to name
const PROCESSING_SUCCEEDED = 'APP_SUCCEEDED'; // TODO : rename to name
const PROCESSING_FAILED = 'APP_FAILED'; // TODO : rename to name

export const STATE_PENDING = 'pending'
export const STATE_RUNNING = 'running'
export const STATE_SUCCEEDED = 'succeeded'
export const STATE_FAILED = 'failed'
export const STATE_CANCELLED = 'cancelled'
export const STATE_UNKNOWN = 'unknown'

export const PHASE_COLLECTING = 'collecting'
export const PHASE_UPLOADING = 'uploading'
export const PHASE_PROCESSING = 'processing'


function filterEventsByDrone(events, drone) {
    return events.filter((event) => event.drone && event.drone.id === drone.id);
}

function filterEventsByApp(events, app) {
    return events.filter((event) => event.app && event.app.id === app?.id);
}

function filterEventsByTypes(events, eventTypes) {
    return events.filter((event) => eventTypes.includes(event.type));
}

function anyEventsByTypes(events, eventTypes) {
    return filterEventsByTypes(events, eventTypes).length > 0;
}

function getLatestEvent(events) {
    return events[events.length - 1] || null;
}

function latestEventIsOfType(events, eventType) {
    return getLatestEvent(events)?.type === eventType;
}

export function getMissionFlights(mission) {
    return mission?.flights || [];
}

export function getMissionDrones(mission) {
    if (mission?.drones) return mission.drones;
    if (mission?.drone) return [mission.drone];
    return [];
}

export function getMissionApps(mission) {
    return mission.apps || [];
}

// DRONE COLLECTING INDIVIDUAL

export function droneStartedCollecting(mission, drone) {
    const droneEvents = filterEventsByDrone(mission.events, drone);
    const eventTypesOfInterest = [COLLECTING_STARTED];
    const events = filterEventsByTypes(droneEvents, eventTypesOfInterest);
    return latestEventIsOfType(events, COLLECTING_STARTED);
}

export function droneSucceededCollecting(mission, drone) {
    const droneEvents = filterEventsByDrone(mission.events, drone);
    const eventTypesOfInterest = [COLLECTING_SUCCEEDED];
    const events = filterEventsByTypes(droneEvents, eventTypesOfInterest);
    return latestEventIsOfType(events, COLLECTING_SUCCEEDED);
}

export function droneFailedCollecting(mission, drone) {
    const droneEvents = filterEventsByDrone(mission.events, drone);
    const eventTypesOfInterest = [COLLECTING_FAILED];
    const events = filterEventsByTypes(droneEvents, eventTypesOfInterest);
    return latestEventIsOfType(events, COLLECTING_FAILED);
}

export function getFlightState(mission, flight) {
    const flightState = flight?.state || STATE_UNKNOWN;

    if (flightState !== STATE_UNKNOWN) {
        return flightState;
    }
    // the flight has no state, fall back to the old methods
    if (filterEventsByTypes(flight.events, ["FLIGHT_SUCCEEDED"])) {
        return STATE_SUCCEEDED;
    }
    if (filterEventsByTypes(flight.events, ["FLIGHT_SUCCEEDED"])) {
        return STATE_FAILED;
    }
    if (filterEventsByTypes(flight.events, ["FLIGHT_STARTED"])) {
        return STATE_RUNNING;
    }
    if (filterEventsByTypes(flight.events, ["FLIGHT_INITIATED"])) {
        return STATE_PENDING;
    }
    return STATE_UNKNOWN;
}

// DRONE COLLECTING AGGREGATE

export function anyDroneStartedCollecting(mission) {
    const drones = getMissionDrones(mission);
    return drones.length !== 0 && drones.some((drone) => {
        return droneStartedCollecting(mission, drone);
    });
}

export function everyDroneSucceededCollecting(mission) {
    const drones = getMissionDrones(mission);
    return drones.length !== 0 && drones.every((drone) => {
        return droneSucceededCollecting(mission, drone);
    });
}

export function anyDroneFailedCollecting(mission) {
    const drones = getMissionDrones(mission);
    return drones.length !== 0 && drones.some((drone) => {
        return droneFailedCollecting(mission, drone);
    });
}

export function everyDroneFinishedCollecting(mission) {
    return anyDroneFailedCollecting(mission) || everyDroneSucceededCollecting(mission);
}

// DRONE UPLOADING INDIVIDUAL

export function droneStartedUploading(mission, drone) {
    const droneEvents = filterEventsByDrone(mission.events, drone);
    const eventTypesOfInterest = [UPLOADING_STARTED];
    const events = filterEventsByTypes(droneEvents, eventTypesOfInterest);
    return latestEventIsOfType(events, UPLOADING_STARTED);
}

export function droneSucceededUploading(mission, drone) {
    const droneEvents = filterEventsByDrone(mission.events, drone);
    const eventTypesOfInterest = [UPLOADING_SUCCEEDED];
    const events = filterEventsByTypes(droneEvents, eventTypesOfInterest);
    return latestEventIsOfType(events, UPLOADING_SUCCEEDED);
}

export function droneFailedUploading(mission, drone) {
    const droneEvents = filterEventsByDrone(mission.events, drone);
    const eventTypesOfInterest = [UPLOADING_FAILED];
    const events = filterEventsByTypes(droneEvents, eventTypesOfInterest);
    return latestEventIsOfType(events, UPLOADING_FAILED);
}


// DRONE UPLOADING AGGREGATE

export function anyDroneStartedUploading(mission) {
    const drones = getMissionDrones(mission);
    return drones.length !== 0 && drones.some((drone) => {
        return droneStartedUploading(mission, drone);
    });
}

export function everyDroneSucceededUploading(mission) {
    const drones = getMissionDrones(mission);
    return drones.length !== 0 && drones.every((drone) => {
        return droneSucceededUploading(mission, drone);
    });
}

export function anyDroneFailedUploading(mission) {
    const drones = getMissionDrones(mission);
    return drones.length !== 0 && drones.some((drone) => {
        return droneFailedUploading(mission, drone);
    });
}

export function everyDroneFinishedUploading(mission) {
    return anyDroneFailedUploading(mission) || everyDroneSucceededUploading(mission);
}

// APP PROCESSING INDIVIDUAL

export function appStartedProcessing(mission, app) {
    const appState = app?.state || STATE_UNKNOWN;
    if ([STATE_FAILED, STATE_SUCCEEDED, STATE_RUNNING].includes(appState)) {
        return true;
    } else if ([STATE_CANCELLED, STATE_PENDING].includes(appState)) {
        return false;
    } else {
        // the state is unknown fall back on the events
        const appEvents = filterEventsByApp(mission.events, app);
        const eventTypesOfInterest = [PROCESSING_STARTED];
        const events = filterEventsByTypes(appEvents, eventTypesOfInterest);
        return latestEventIsOfType(events, PROCESSING_STARTED);
    }
}

export function appSucceededProcessing(mission, app) {
    const appState = app?.state || STATE_UNKNOWN;
    if ([STATE_SUCCEEDED].includes(appState)) {
        // succeeded
        return true;
    } else if (![STATE_UNKNOWN].includes(appState)) {
        // any state not succeeded or unknown
        return false;
    } else {
        // the state is unknown fall back on the events
        const appEvents = filterEventsByApp(mission.events, app);
        const eventTypesOfInterest = [PROCESSING_SUCCEEDED];
        return anyEventsByTypes(appEvents, eventTypesOfInterest);
    }
}

export function appFailedProcessing(mission, app) {
    const appState = app?.state || STATE_UNKNOWN;
    if ([STATE_FAILED].includes(appState)) {
        // failed
        return true;
    } else if (![STATE_UNKNOWN].includes(appState)) {
        // any state not succeeded or unknown
        return false;
    } else {
        // the state is unknown fall back on the events
        const appEvents = filterEventsByApp(mission.events, app);
        const eventTypesOfInterest = [PROCESSING_STARTED, PROCESSING_SUCCEEDED, PROCESSING_FAILED];
        const events = filterEventsByTypes(appEvents, eventTypesOfInterest);
        return latestEventIsOfType(events, PROCESSING_FAILED);
    }
}

export function getApplicationState(mission, app) {
    const state = app?.state || STATE_UNKNOWN;
    if (state !== STATE_UNKNOWN) {
        return state;
    }

    if (appFailedProcessing(mission, app)) {
        return STATE_FAILED;
    }
    if (appSucceededProcessing(mission, app)) {
        return STATE_SUCCEEDED;
    }
    if (appStartedProcessing(mission, app)) {
        return STATE_RUNNING;
    }
    return STATE_PENDING;
}

// APP PROCESSING AGGREGATE

export function anyAppStartedProcessing(mission) {
    const apps = getMissionApps(mission);
    return apps.length !== 0 && apps.some((app) => {
        return appStartedProcessing(mission, app);
    });
}

export function everyAppSucceededProcessing(mission) {
    const apps = getMissionApps(mission);
    return apps.length !== 0 && apps.every((app) => {
        return appSucceededProcessing(mission, app);
    });
}

export function anyAppFailedProcessing(mission) {
    const apps = getMissionApps(mission);
    return apps.length !== 0 && apps.some((app) => {
        return appFailedProcessing(mission, app);
    });
}

export function everyAppFinishedProcessing(mission) {
    return anyAppFailedProcessing(mission) || everyAppSucceededProcessing(mission);
}

// MISSION PROGRESS

export function CollectingState(mission) {
    const collectionState = mission?.states?.collecting || STATE_UNKNOWN;
    if (collectionState !== STATE_UNKNOWN) {
        return collectionState;
    }

    // check if the collecting has succeeded
    if (everyDroneSucceededCollecting(mission)) {
        return STATE_SUCCEEDED;
    }

    // check if collecting has failed
    if (anyDroneFailedCollecting(mission)) {
        return STATE_FAILED;
    }

    // check that collecting is not started yet
    if (!anyDroneStartedCollecting(mission)) {
        return STATE_PENDING;
    }

    // check that collecting is still running
    if (!everyDroneFinishedCollecting(mission)) {
        return STATE_RUNNING;
    }

    return STATE_UNKNOWN;
}

export function UploadingState(mission) {
    const UploadingState = mission?.states?.uploading || STATE_UNKNOWN;
    if (UploadingState !== STATE_UNKNOWN) {
        return UploadingState;
    }

    // check if the collecting has succeeded
    if (everyDroneSucceededUploading(mission)) {
        return STATE_SUCCEEDED;
    }

    // check if collecting has failed
    if (anyDroneFailedUploading(mission)) {
        return STATE_FAILED;
    }

    // check that collecting is not started yet
    if (!anyDroneStartedUploading(mission)) {
        return STATE_PENDING;
    }

    // check that collecting is still running
    if (!everyDroneFinishedUploading(mission)) {
        return STATE_RUNNING;
    }

    return STATE_UNKNOWN;
}

export function ProcessingState(mission) {
    const ProcessingState = mission?.states?.processing || STATE_UNKNOWN;
    if (ProcessingState !== STATE_UNKNOWN) {
        return ProcessingState;
    }

    // check if the collecting has succeeded
    if (everyAppSucceededProcessing(mission)) {
        return STATE_SUCCEEDED;
    }

    // check if collecting has failed
    if (anyAppFailedProcessing(mission)) {
        return STATE_FAILED;
    }

    // check that collecting is not started yet
    if (!anyAppStartedProcessing(mission)) {
        return STATE_PENDING;
    }

    // check that collecting is still running
    if (!everyAppFinishedProcessing(mission)) {
        return STATE_RUNNING;
    }

    return STATE_UNKNOWN;
}

export function getMissionState(mission) {
    const proccessingState = ProcessingState(mission);
    const uploadingState = UploadingState(mission);
    const collectingState = CollectingState(mission);

    if ([STATE_SUCCEEDED, STATE_FAILED, STATE_RUNNING, STATE_UNKNOWN].includes(proccessingState)) {
        // the apps have succeeded or failed, or are running. or the state is unknown
        return proccessingState;
    }
    if ([STATE_PENDING].includes(proccessingState)) {
        // processing is still pending, check on the upload state

        if ([STATE_FAILED, STATE_RUNNING, STATE_UNKNOWN].includes(uploadingState)) {
            // uploading is running, failed
            return uploadingState;
        } if ([STATE_SUCCEEDED].includes(uploadingState)) {
            // upload has just succeeded and processing is pending, we assume it is still running and just have to wait for processing to start
            return STATE_RUNNING
        } if ([STATE_PENDING].includes(uploadingState)) {
            // upload is pending check for the collecting state

            if ([STATE_FAILED, STATE_RUNNING, STATE_UNKNOWN, STATE_PENDING, STATE_CANCELLED].includes(collectingState)) {
                return collectingState;
            }
            if ([STATE_SUCCEEDED].includes(collectingState)) {
                // collecting has started but uploading is pending, just wait for the upload to start
                return STATE_RUNNING;
            }

        } if ([STATE_CANCELLED].includes(uploadingState)) {
            // uploading has been canceled, but processing is still pending? should not happen mission has failed
            return STATE_FAILED;
        }
    }
    if ([STATE_CANCELLED].includes(proccessingState)) {
        // the processing has been canceled, look at the uploading state

        if ([STATE_FAILED, STATE_UNKNOWN].includes(uploadingState)) {
            return uploadingState;
        }
        if ([STATE_RUNNING, STATE_SUCCEEDED, STATE_PENDING].includes(uploadingState)) {
            return STATE_CANCELLED;
        }
        if ([STATE_CANCELLED].includes(uploadingState)) {
            // uploading was also cancelled, check the collecting state

            if ([STATE_FAILED, STATE_UNKNOWN].includes(collectingState)) {
                return collectingState;
            } else {
                return STATE_CANCELLED;
            }
        }
    }
    return STATE_UNKNOWN
}


export function getMissionActivity(mission) {
    const missionState = getMissionState(mission);
    const proccessingState = ProcessingState(mission);
    const uploadingState = UploadingState(mission);
    const collectingState = CollectingState(mission);

    if ([STATE_SUCCEEDED, STATE_FAILED, STATE_CANCELLED, STATE_UNKNOWN].includes(missionState)) {
        return missionState;
    }
    if ([STATE_PENDING].includes(missionState)) {
        return "waiting";
    }
    if ([STATE_RUNNING].includes(missionState)) {
        if ([STATE_RUNNING].includes(collectingState)) {
            return "collecting";
        }
        if ([STATE_RUNNING].includes(uploadingState)) {
            return "uploading";
        }
        if ([STATE_RUNNING].includes(proccessingState)) {
            return "processing";
        }
    }
    return STATE_UNKNOWN;
}