import * as missionApi from "../../api/missionApi";
import TimeDisplay from "../TimeDisplay";
import StateIcon from "../StateIcon";

export default function DroneCollectStatusMessage(props) {


    return (
        <span>
            <StateIcon
                state={missionApi.getFlightState(props.mission, props.flight)}
                className={"me-4"}
            />
            Flight by {props.flight?.drone?.id || "some drone"} at <TimeDisplay
                timestamp={props.flight.timestamp.seconds}
                format="HH:mm:ss"
                organizationId={props.organizationId}
                locationId={props.locationId}
            />
        </span>
    );
}
