import * as missionApi from "../../api/missionApi";
import StateIcon from "../StateIcon";

export default function MissionStatusMessage(props) {
    return (
        <div>
            <StateIcon
                state={missionApi.getMissionState(props.mission)}
                className='me-4'
            />
            {props.children}
        </div>
    );

}
