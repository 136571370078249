import React from "react";
import queryString from 'query-string';
import { Button, ButtonGroup, Card, Dropdown, Form, Spinner } from "react-bootstrap";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { FaCode, FaMap, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import ModalImage from "react-modal-image";
import mergeImages from 'merge-images';
import { Link, useParams, useHistory } from "react-router-dom";
import * as api from "../../api/api";
import * as firestoreApi from "../../api/firestoreApi.js";
import BackButton from "../../components/BackButton";
import CodeBlock from "../../components/CodeBlock";
import DevMenuButton from "../../components/DevMenuButton";
import FirestoreDevMenuItem from "../../components/FirestoreDevMenuItem";
import { MeasurementTagger } from "../../components/ItemTagger";
import OverlaySelector from "../../components/OverlaySelector";
import CorvusSetting from "../../components/CorvusSetting";
import Layout, { ImageBreadcrumbContainer, Title } from "../../components/Layout/Layout";
import { useUser } from "../../contexts/user_provider";
import { getFirebaseFirestore, getFirebaseStorageDownloadUrl } from "../../corvusFirebase.js";
import TimeDisplay from '../../components/TimeDisplay/index.jsx';
import urls from "../../urls.js";
import "./index.css";

export default function ImagePage(props) {
    const [measurement, setMeasurement] = React.useState(null);
    const [nextMeasurement, setNextMeasurement] = React.useState(undefined);
    const [previousMeasurement, setPreviousMeasurement] = React.useState(undefined);
    const { organizationId, locationId, missionId, measurementId } = useParams();
    const [view, setView] = React.useState('data');
    const [imageUrl, setImageUrl] = React.useState(null);
    const [overlays, setOverlays] = React.useState([]);
    const [backgroundUrl, setBackgroundUrl] = React.useState(null);
    const [computedOverlays, setComputedOverlays] = React.useState({});
    const [selectedOverlays, setSelectedOverlays] = React.useState(() => {
        const overlaysQuery = queryString.parse(props.location.search).overlays;
        return overlaysQuery ? [overlaysQuery] : [];
    });
    const [availableOverlays, setAvailableOverlays] = React.useState([]);
    const [overlayLoadingStates, setOverlayLoadingStates] = React.useState({});
    const { getUserIsAdmin } = useUser();
    const history = useHistory();

    function resetPage() {
        setBackgroundUrl(null);
        setOverlays([]);
        setComputedOverlays({});
        setImageUrl(null);
        setOverlayLoadingStates({})
    }
    const hasNextMeasurement = React.useCallback(() => {
        return nextMeasurement !== undefined;
    }, [nextMeasurement]);

    const hasPreviousMeasurement = React.useCallback(() => {
        return previousMeasurement !== undefined;
    }, [previousMeasurement]);

    const visitNextMeasurement = React.useCallback(() => {
        if (hasNextMeasurement()) {
            setImageUrl(null); // Ensure that the old image is gone
            resetPage();
            history.push(urls.measurement(organizationId, locationId, missionId, nextMeasurement.id));
        }
    }, [hasNextMeasurement, setImageUrl, history, organizationId, locationId, missionId, nextMeasurement]);

    const visitPreviousMeasurement = React.useCallback(() => {
        if (hasPreviousMeasurement()) {
            setImageUrl(null); // Ensure that the old image is gone
            resetPage();
            history.push(urls.measurement(organizationId, locationId, missionId, previousMeasurement.id));
        }
    }, [hasPreviousMeasurement, setImageUrl, history, organizationId, locationId, missionId, previousMeasurement]);

    React.useEffect(() => {
        const fetchPreviousAndNextMeasurement = async () => {
            if (measurement) {
                // Subscribe to previous measurement
                const previousMeasurementQuery = getFirebaseFirestore()
                    .collection(`organizations/${organizationId}/locations/${locationId}/measurements`)
                    .where('event.summary.MEASUREMENT_STARTED', '<', measurement.event.summary.MEASUREMENT_STARTED)
                    .where('meta.mission.id', '==', missionId)
                    .orderBy('event.summary.MEASUREMENT_STARTED', 'desc')
                    .limit(1);

                const previousMeasurementUnsubscrive = previousMeasurementQuery.onSnapshot((snapshot) => {
                    const newPreviousMeasurement = snapshot.docs.map((doc) => ({
                        ...doc.data(),
                        id: doc.id,
                    }))[0];

                    setPreviousMeasurement(newPreviousMeasurement)
                });

                // Subscribe to next measurement
                const nextMeasurementQuery = getFirebaseFirestore()
                    .collection(`organizations/${organizationId}/locations/${locationId}/measurements`)
                    .where('event.summary.MEASUREMENT_STARTED', '>', measurement.event.summary.MEASUREMENT_STARTED)
                    .where('meta.mission.id', '==', missionId)
                    .orderBy('event.summary.MEASUREMENT_STARTED', 'asc')
                    .limit(1);

                const nextMeasurementUnsubscribe = nextMeasurementQuery.onSnapshot((snapshot) => {
                    const newNextMeasurement = snapshot.docs.map((doc) => ({
                        ...doc.data(),
                        id: doc.id,
                        organizationId: organizationId,
                        locationId: locationId,
                    }))[0];

                    setNextMeasurement(newNextMeasurement)
                });

                return () => {
                    // Unsubscribe when the component unmounts
                    previousMeasurementUnsubscrive();
                    nextMeasurementUnsubscribe();
                };
            }
        };

        fetchPreviousAndNextMeasurement();
    }, [organizationId, locationId, missionId, measurement]);

    React.useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowLeft') {
                visitPreviousMeasurement();
            } else if (event.key === 'ArrowRight') {
                visitNextMeasurement();
            }
        };

        // Add event listeners when the component mounts
        window.addEventListener('keydown', handleKeyDown);

        // Remove event listeners when the component unmounts
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [visitPreviousMeasurement, visitNextMeasurement]);

    React.useEffect(() => {
        return api.subscribeMeasurement(organizationId, locationId, measurementId, setMeasurement);
    }, [organizationId, locationId, missionId, measurementId]);

    // get all overlays that are available for this mission
    React.useEffect(() => {
        api.subscribeMission(organizationId, locationId, missionId, (data) => {
            console.log("overlays", data.overlays);
            setAvailableOverlays(data.overlays || []);
        });
    }, [organizationId, locationId, missionId]);

    // get all the overlays that are available for this measurement
    React.useEffect(() => {
        if (measurementId) {
            // subscribe to the overlay images
            const overlayCollection = new firestoreApi.OverlayCollection(organizationId, locationId, measurementId)
            async function getOverlayUrls(overlays) {
                let urlOverlays = await Promise.all(overlays.map(async (overlay) => {
                    try {
                        const bucketPath = `organizations/${organizationId}/locations/${locationId}/measurements/${measurementId}/overlays/${overlay.id}.png`;
                        return await getFirebaseStorageDownloadUrl(bucketPath).then((url) => { return { ...overlay, url: url } });
                    } catch {
                        return null;
                    }
                }));
                setOverlays(urlOverlays.filter(overlay => overlay !== null));
            }
            overlayCollection.subscribe(getOverlayUrls);
        }
    }, [organizationId, locationId, measurementId])

    React.useEffect(() => {
        if (backgroundUrl && overlays.length > 0) {
            // Map over overlays to create promises for each overlay's image merging
            const promises = overlays.map(async (overlay) => {
                if (overlay.url) {
                    // Set loading state for this specific overlay when its processing starts
                    setOverlayLoadingStates(prevState => ({
                        ...prevState,
                        [overlay.name]: true,
                    }));

                    try {
                        const output = await mergeImages([backgroundUrl, overlay.url], {
                            crossOrigin: 'Anonymous',
                            format: 'image/jpeg',
                            quality: 0.9
                        });

                        setComputedOverlays(prevComputedOverlays => ({
                            ...prevComputedOverlays,
                            [overlay.name]: output
                        }));

                        setOverlayLoadingStates(prevState => ({
                            ...prevState,
                            [overlay.name]: false,
                        }));
                    } catch (error) {
                        console.log(overlay.name, error);
                        setOverlayLoadingStates(prevState => ({
                            ...prevState,
                            [overlay.name]: false,
                        }));
                    }
                }
            });

            // Run all promises in parallel
            Promise.all(promises).then(() => { });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, locationId, measurementId, backgroundUrl, overlays]);

    // See which ones are selected
    const handleOverlaySelectionChange = (SelectedOverlayName) => {
        if (SelectedOverlayName.length > 0) {
            setSelectedOverlays([SelectedOverlayName])
        } else {
            setSelectedOverlays([])
        }
    }

    // set the measurement image to either just the measurement, or combine it with the overlays and display that
    React.useEffect(() => {
        if (measurementId) {
            const bucketPath = `organizations/${organizationId}/locations/${locationId}/measurements/${measurementId}.jpg`;
            return getFirebaseStorageDownloadUrl(bucketPath).then((url) => {
                setBackgroundUrl(url);
                if (imageUrl === null) {
                    setImageUrl(url);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, locationId, measurementId]);

    // set the measurement image to either just the measurement, or combine it with the overlays and display that
    React.useEffect(() => {
        if (measurementId) {
            if (selectedOverlays.length === 0) {
                // set just the background image as image
                setImageUrl(backgroundUrl);
            } else {
                const newContent = computedOverlays[selectedOverlays[0]];
                if (newContent === undefined) {
                    setImageUrl(backgroundUrl);
                } else {
                    setImageUrl(newContent);
                }
            }
        }
    }, [organizationId, locationId, measurementId, computedOverlays, selectedOverlays, backgroundUrl]);

    return (
        <Layout>
            <ImageBreadcrumbContainer />
            <Card className="mb-4">
                <Title title={
                    <>
                        <BackButton
                            href={urls.missionMeasurements(organizationId, locationId, missionId)}
                        />
                        Measurement {measurement && <TimeDisplay
                            timestamp={new Date(measurement.event.summary.MEASUREMENT_STARTED).getTime() / 1000}
                            format="YYYY-MM-DD HH:mm:ss"
                            organizationId={organizationId}
                            locationId={locationId}
                        />}
                    </>
                }>
                    <div>
                        {getUserIsAdmin() && (
                            <DevMenuButton>
                                <Dropdown.Item onClick={() => setView(view === 'data' ? 'meta' : 'data')}>
                                    <FaCode
                                        className="me-2"
                                        style={{
                                            marginTop: "-2px",
                                        }}
                                    />
                                    {view === 'data' && <>View Meta</>}
                                    {view === 'meta' && <>View Data</>}
                                </Dropdown.Item>
                                <FirestoreDevMenuItem
                                    as={Dropdown.Item}
                                    path={firestoreApi.getMeasurementDocumentPath(organizationId, locationId, measurementId)}
                                />
                                {measurement && (
                                    <>
                                        {measurement.meta.position.x && measurement.meta.position.y &&
                                            < Dropdown.Item
                                                as={Link}
                                                to={`${urls.locationMap(organizationId, locationId)}?missionId=${measurement.meta.mission.id}&x=${measurement.meta.position.x}&y=${measurement.meta.position.y}`}
                                            >
                                                <FaMap
                                                    className="me-2"
                                                    style={{ marginTop: "-2px" }}
                                                />
                                                View on map
                                            </Dropdown.Item>
                                        }
                                        <Dropdown.Item
                                            as={Link}
                                            to={urls.flight(organizationId, locationId, measurement.meta.flight.id)}
                                        >
                                            Go to Flight
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            as={Link}
                                            to={urls.locationMission(organizationId, locationId, measurement.meta.mission.id)}
                                        >
                                            Go to Mission
                                        </Dropdown.Item>
                                    </>
                                )}
                            </DevMenuButton>
                        )}
                        <ButtonGroup>
                            <Button
                                variant="outline-secondary"
                                className='px-4'
                                onClick={visitPreviousMeasurement}
                                disabled={!hasPreviousMeasurement()}
                            >
                                <FaArrowLeft
                                    style={{
                                        marginTop: "-2px",
                                    }}
                                />
                            </Button>
                            <Button
                                variant="outline-secondary"
                                className='px-4'
                                onClick={visitNextMeasurement}
                                disabled={!hasNextMeasurement()}
                            >
                                <FaArrowRight
                                    style={{
                                        marginTop: "-2px",
                                    }}
                                />
                            </Button>
                        </ButtonGroup>
                    </div>
                </Title>
                {view === 'meta' && (
                    <Card.Body className='p-4 pt-0'>
                        <CodeBlock name='Measurement' code={measurement} />
                    </Card.Body>
                )}
                {view === 'data' && (
                    <>
                        {getUserIsAdmin() && (
                            <Card.Body className="p-4 pt-0">
                                <MeasurementTagger
                                    selected={measurement?.tags || []}
                                    onChange={(newTags) => api.updateMeasurementTags(organizationId, locationId, measurementId, newTags)}
                                />
                            </Card.Body>
                        )}

                        <Card.Body className='p-4 pt-0'>
                            <div className="container">
                                {availableOverlays.length > 0 && (
                                    <Form>
                                        <Form.Group>
                                            <CorvusSetting name="Overlay" className="mb-2 d-flex align-items-center">
                                                <OverlaySelector
                                                    overlays={availableOverlays}
                                                    selectedOverlay={selectedOverlays[0]}
                                                    onChange={handleOverlaySelectionChange}
                                                />
                                                {selectedOverlays[0] && overlayLoadingStates[selectedOverlays[0]] && (
                                                    // Put this in a separate div to avoid styles from the container being applied
                                                    <div>
                                                        <Spinner
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            className="ms-2"
                                                        >
                                                            <span className="visually-hidden">Loading overlay...</span>
                                                        </Spinner>
                                                    </div>
                                                )}
                                            </CorvusSetting>
                                        </Form.Group>
                                    </Form>
                                )}
                                <ModalImage
                                    // hideZoom={true}
                                    hideDownload={true}
                                    small={imageUrl}
                                    large={imageUrl}
                                    className="backgroundImage"
                                />
                                <img class="overlay" src="" alt=""></img>
                            </div>
                        </Card.Body>
                    </>
                )}
            </Card>
        </Layout >
    );
}
