import React from "react";
import * as missionApi from "../../api/missionApi";
import StateIcon from "../StateIcon";

export default function AppStatusMessage(props) {

    return (
        <span>
            <StateIcon
                state={missionApi.getApplicationState(props.mission, props.app)}
                className={"me-4"}
            />
            {props.app?.name || "Unknown app"}
        </span>
    );
}