import React from "react";
import { Container, Sprite, Text } from "@inlet/react-pixi";
import { useMapContext } from '../MapContext';

const droneTextStyle = {
    align: "center",
    fontWeight: "bold",
    fill: 'white',
    fontSize: 32,
    stroke: '#000000',
    strokeThickness: 2,
};

export const Drone = React.memo(function Drone({ droneId, x, y, yaw, isOnline }) {
    const { worldToMap } = useMapContext();

    const droneImage = isOnline
        ? process.env.PUBLIC_URL + "/drone.png"
        : process.env.PUBLIC_URL + "/drone_offline.png";

    const offlineDroneTextStyle = {
        ...droneTextStyle,
        fill: 'gray',
    };

    const appliedTextStyle = isOnline ? droneTextStyle : offlineDroneTextStyle;

    return (
        <Container>
            <Sprite
                image={droneImage}
                scale={{ x: 0.03, y: 0.03 }}
                anchor={0.5}
                angle={-yaw}
                {...worldToMap({ x, y })}
            />
            <Text
                text={droneId}
                anchor={0.5}
                {...worldToMap({ x, y })}
                style={appliedTextStyle}
                scale={0.125}
            />
        </Container>
    );
});
