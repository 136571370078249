import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FaSkull } from "react-icons/fa";

import SlideToUnlock from "../../components/SlideToUnlock";
import * as api from "../../api/api";




export default function EmergencyLandSlider(props) {
    const [isEmergencyLanding, setIsEmergencyLanding] = React.useState(false);

    function thisDroneIsFlying() {
        if (!props.drone) return false;
        if (!props.droneFeedback) return false;
        if (!props.droneFeedback.widgets) return false;

        return api.droneIsFlying(props.droneFeedback.widgets);
    }

    React.useEffect(() => {
        // Reset state when drone stops flying
        if (!thisDroneIsFlying()) {
            setIsEmergencyLanding(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.drone, props.droneFeedback]);

    return thisDroneIsFlying() && (
        <Row>
            <Col>
                <span className="me-5">
                    {isEmergencyLanding ?
                        (
                            <>
                                <Button variant="danger" className="mt-2 btn-sm" onClick={(e) => { api.sendKill(props.drone.id, props.droneFeedback.widgets.missions); e.stopPropagation(); }}>
                                    <FaSkull className="me-2" />
                                    Kill
                                </Button>
                            </>
                        ) : (
                            <SlideToUnlock
                                onUnlock={function unlock() {
                                    api.sendEmergencyLand(props.drone.id, props.droneFeedback.widgets.missions);
                                    setIsEmergencyLanding(true);
                                }}
                                small={props.small}
                                display="Slide to land >>"
                            />
                        )}
                </span>
            </Col>
        </Row >
    )
}
