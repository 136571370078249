import React from "react";
import { Container, Graphics, Text } from "@inlet/react-pixi";
import { useMapContext } from '../MapContext';

const areaTextStyle = {
    align: "center",
    fontWeight: "bold",
    fontSize: 42,
    stroke: '#ffffff',
    strokeThickness: 3,
}

export const Area = React.memo(function Area({ label, x, y, width, height }) {
    const { worldToMap } = useMapContext();

    const draw = (graphic) => {
        graphic.clear();
        graphic.lineStyle(1, 0x000000, 1, 0.5);
        graphic.beginFill(0xffffff, 0.1);
        graphic.drawRect(...Object.values(worldToMap({ x, y, width, height })));
        graphic.endFill();
    };

    return (
        <Container>
            <Graphics draw={draw} />
            <Text
                text={label}
                anchor={0.5}
                {...worldToMap({ x: x + width / 2, y: y + height / 2 })}
                style={areaTextStyle}
                scale={0.10}
            />
        </Container>
    );
});