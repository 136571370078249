import React from "react";
import InlineFormInput from "../../FormGroups/InlineFormInput";
import { useController } from 'react-hook-form';
import { naturalSort } from "../../../utils/sortingUtils";

export default function Edit({ command, commandIdx, areas, control, watch, errors }) {
    
    const bayOptions = Object.keys(areas).sort(naturalSort).map(key => ({
        value: key,
        label: key
    }));

    const selectedStartBay = useController({
        name: `commands.${commandIdx}.settings.startKap`,
        control,
        defaultValue: command.settings?.startKap || "",
    });

    const selectedEndBay = useController({
        name: `commands.${commandIdx}.settings.endKap`,
        control,
        defaultValue: command.settings?.endKap || "",
    });

    const startPostOptions = selectedStartBay.field.value && areas[selectedStartBay.field.value]
        ? Object.keys(areas[selectedStartBay.field.value]).sort(naturalSort).map(key => ({
            value: key,
            label: key
        }))
        : [];

    const endPostOptions = selectedEndBay.field.value && areas[selectedEndBay.field.value]
        ? Object.keys(areas[selectedEndBay.field.value]).sort(naturalSort).map(key => ({
            value: key,
            label: key
        }))
        : [];

    return (
        <>
            <InlineFormInput
                name={`commands.${commandIdx}.settings.startKap`}
                control={control}
                defaultValue={command.settings?.startKap || ""}
                errors={errors?.commands?.[commandIdx]?.settings?.startKap}
                placeholder="Bay"
                inputType="select"
                options={bayOptions}
                field={selectedStartBay.field}
            />

            <InlineFormInput
                name={`commands.${commandIdx}.settings.startLeg`}
                control={control}
                defaultValue={command.settings?.startLeg || ""}
                errors={errors?.commands?.[commandIdx]?.settings?.startLeg}
                placeholder="Post"
                inputType="select"
                options={startPostOptions}
            />

            <span className="my-2 me-2">until</span>

            <InlineFormInput
                name={`commands.${commandIdx}.settings.endKap`}
                control={control}
                defaultValue={command.settings?.endKap || ""}
                errors={errors?.commands?.[commandIdx]?.settings?.endKap}
                placeholder="Bay"
                inputType="select"
                options={bayOptions}
                field={selectedEndBay.field}
            />

            <InlineFormInput
                name={`commands.${commandIdx}.settings.endLeg`}
                control={control}
                defaultValue={command.settings?.endLeg || ""}
                errors={errors?.commands?.[commandIdx]?.settings?.endLeg}
                placeholder="Post"
                inputType="select"
                options={endPostOptions}
            />
        </>
    );
}
