import { label } from "./Attributes";

export default function Preview({ command }) {
    return (
        <span className="m-3">
            <span className="me-2">{`${label} to`}</span>
            <code className="me-2">
                {command.settings.picture_distance || 0}{'m'}
            </code>
        </span>

    );
}