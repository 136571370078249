import { label } from "./Attributes";

export default function Preview({ command }) {
    return (
        <span className="m-3">
            <span className="me-2">{label}</span>
            <code className="me-2">
                {`${command.settings.startKap} ${command.settings.startLeg}`}
            </code>
            <span className="me-2">until</span>
            <code className="me-2">
                {`${command.settings.endKap} ${command.settings.endLeg}`}
            </code>
        </span>
    );
}