import React from "react";
import InlineFormInput from "../../FormGroups/InlineFormInput";
import { useController } from 'react-hook-form';

export default function Edit({ command, commandIdx, areas, control, watch, errors }) {

    const waypointYaw = useController({
        name: `commands.${commandIdx}.settings.yaw`,
        control,
        defaultValue: command.settings?.yaw || "",
    });

    const waypointUseFlyzoneYaw = useController({
        name: `commands.${commandIdx}.settings.useFlyzoneYaw`,
        control,
        defaultValue: command.settings?.useFlyzoneYaw ?? (command.settings?.yaw === undefined || command.settings?.yaw === ""),
    });

    const yawSelectionOptions = [
        { value: true, label: 'Flyzone yaw' },
        { value: false, label: 'Custom yaw' },
    ];

    // Custom onChange to handle additional logic
    const handleYawTypeChange = (value) => {
        const isCustom = value === false;
        if (!isCustom) {
            waypointYaw.field.onChange(""); // Ensure yaw is cleared if "Flyzone yaw" is selected
        }
    };

    return (
        <>
            <span className="my-2 mx-1">xyz</span>
            <InlineFormInput
                name={`commands.${commandIdx}.settings.x`}
                control={control}
                defaultValue={command.settings?.x || ""}
                errors={errors?.commands?.[commandIdx]?.settings?.x}
                placeholder="x"
                inputType="text"
                unit="m"
            />

            <InlineFormInput
                name={`commands.${commandIdx}.settings.y`}
                control={control}
                defaultValue={command.settings?.y || ""}
                errors={errors?.commands?.[commandIdx]?.settings?.y}
                placeholder="y"
                inputType="text"
                unit="m"
            />

            <InlineFormInput
                name={`commands.${commandIdx}.settings.z`}
                control={control}
                defaultValue={command.settings?.z || ""}
                errors={errors?.commands?.[commandIdx]?.settings?.z}
                placeholder="z"
                inputType="text"
                unit="m"
            />

            <InlineFormInput
                name={`commands.${commandIdx}.settings.useFlyzoneYaw`}
                control={control}
                defaultValue={command.settings?.useFlyzoneYaw ?? true}
                errors={errors?.commands?.[commandIdx]?.settings?.useFlyzoneYaw}
                placeholder="Yaw Selection"
                inputType="select"
                options={yawSelectionOptions}
                onChange={handleYawTypeChange}
                field={waypointUseFlyzoneYaw.field}
            />

            {!waypointUseFlyzoneYaw.field.value && (
                <InlineFormInput
                    name={`commands.${commandIdx}.settings.yaw`}
                    control={control}
                    defaultValue={command.settings?.yaw || ""}
                    errors={errors?.commands?.[commandIdx]?.settings?.yaw}
                    placeholder="yaw"
                    inputType="text"
                    unit="°"
                    field={waypointYaw.field}
                />
            )}
        </>
    );
}
