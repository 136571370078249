import React from "react";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import * as api from "../../api/api";

export default function DroneSelector(props) {
    const [drones, setDrones] = React.useState([]);
    const [droneFeedback, setDroneFeedback] = React.useState({});
    const { organizationId, locationId } = useParams();

    React.useEffect(() => {
        api.getDroneConfigs(organizationId, locationId, setDrones);
    }, [organizationId, locationId]);

    React.useEffect(() => {
        return api.subscribeDronesFeedback(drones, setDroneFeedback);
    }, [drones]);



    return (
        <Form.Select
            value={props.value}
            onChange={(event) => {
                props.onChange(event.target.value)
            }}
        >
            <option disabled value="none">
                Select a drone ...
            </option>
            {drones.map((drone) => {
                return !api.droneHasActiveMissions(droneFeedback[drone.id]) && (
                    <option
                        key={drone.id}
                        value={drone.id}
                    >
                        {drone.id}
                    </option>
                );
            })}
        </Form.Select>
    );
}