import queryString from 'query-string';
import React from "react";
import { useParams } from "react-router-dom";
import Map from "../../components/Map";
import { Container } from "react-bootstrap";
import Header from '../../components/Header/Header';

export default function MapPage(props) {
    const { organizationId, locationId } = useParams();
    const { flightId, missionId, droneId, taskId, x, y } = queryString.parse(props.location.search);

    const taskPreview = (taskId && droneId) ? { taskId, droneId } : {};
    const resumePreview = (missionId && droneId) ? { missionId, droneId } : {};
    const point = (!isNaN(Number(x)) && !isNaN(Number(y))) ? { x: Number(x), y: Number(y) } : null;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <Header style={{ maxWidth: "850px" }} />
            <Container fluid className="p-0" style={{ flexGrow: 1, overflow: 'hidden' }}>
                <Map
                    organizationId={organizationId}
                    locationId={locationId}
                    flightId={flightId}
                    missionId={(missionId && !droneId) ? missionId : undefined} // Don't show mission overview when resumePreview is shown
                    taskPreview={taskPreview}
                    resumePreview={resumePreview}
                    point={point}
                />
            </Container>
        </div>
    );
}
