import React from "react";
import { Container, Graphics } from "@inlet/react-pixi";
import { processCommand, isV3Task, IS_NOT_RECORDING_COLOR, UNFINISHED_ALPHA } from "../utils/FlightPathUtils";
import { useMapContext } from "../MapContext";


export const DroneCommands = React.memo(function DroneCommands({ droneCommands, abortEvents }) {
    const { worldToMap } = useMapContext();

    const draw = React.useCallback(
        (graphic) => {
            if (Array.isArray(droneCommands) && droneCommands.length > 0) {
                let currentColor = IS_NOT_RECORDING_COLOR;
                let currentAlpha = UNFINISHED_ALPHA;
                let x = undefined;
                let y = undefined;

                graphic.clear();
                graphic.lineStyle(1, currentColor, currentAlpha);
                if (isV3Task(droneCommands)) {
                    const commandMap = new Map(droneCommands.map(command => [command.id, command]));
                    let currentCommand = droneCommands[0];

                    while (currentCommand) {
                        if (abortEvents) {
                            const currentCommandId = currentCommand.id;
                            const matchingAbortEvent = abortEvents.find(event => event.command.id === currentCommandId);
                            if (matchingAbortEvent && matchingAbortEvent.position && matchingAbortEvent.return_command) {
                                console.log(matchingAbortEvent);
                                const { x: abortX, y: abortY } = matchingAbortEvent.position;
                                const nextCommandId = matchingAbortEvent.return_command.id;

                                ({ x, y, currentColor, currentAlpha } = processCommand(currentCommand, graphic, worldToMap, x, y, currentColor, currentAlpha, true, abortX, abortY));

                                currentCommand = commandMap.get(nextCommandId);
                                continue;
                            }
                        }

                        ({ x, y, currentColor, currentAlpha } = processCommand(currentCommand, graphic, worldToMap, x, y, currentColor, currentAlpha));
                        const nextCommandId = currentCommand.next_command;
                        const nextCommand = nextCommandId ? commandMap.get(nextCommandId) : null;
                        if (nextCommand == null) {
                            const returnCommands = currentCommand.return_commands.map(commandId => commandMap.get(commandId));

                            if (returnCommands.length > 0) {
                                // Find the command with the shortest return duration
                                const shortestReturnCommand = returnCommands.reduce((shortest, current) => {
                                    return (current.return_duration < shortest.return_duration) ? current : shortest;
                                });

                                currentCommand = shortestReturnCommand;
                                continue;
                            }
                            else {
                                currentCommand = null;
                            }
                        }
                        else {
                            currentCommand = nextCommand;
                        }
                    }
                } else {
                    droneCommands.forEach(command => {
                        ({ x, y, currentColor, currentAlpha } = processCommand(command, graphic, worldToMap, x, y, currentColor, currentAlpha));
                    });
                }
            }
        },
        [droneCommands, abortEvents, worldToMap]
    );

    return (
        <Container>
            <Graphics draw={draw} />
        </Container>
    );
});