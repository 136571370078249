import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { PixiComponent, useApp } from "@inlet/react-pixi";
import { Viewport as PixiViewport } from 'pixi-viewport';


const PixiComponentViewport = PixiComponent("Viewport", {
    create: (props) => {
        const viewport = new PixiViewport({
            screenWidth: props.screenWidth,
            screenHeight: props.screenHeight,
            worldWidth: props.worldWidth,
            worldHeight: props.worldHeight,

            ticker: props.app.ticker,
            interaction: props.app.renderer.plugins.interaction,
        });

        viewport.drag().pinch().wheel();

        props.app.view.addEventListener('wheel', (event) => {
            event.preventDefault(); // Prevents page scroll on zoom
        });

        viewport.bounce({
            sides: 'all',
            friction: 0.0,
            time: 0,
            ease: 'easeInOutSine',
            underflow: 'center',
        });

        return viewport;
    },
});

const Viewport = forwardRef((props, ref) => {
    const app = useApp();
    const viewportRef = useRef();

    useImperativeHandle(ref, () => ({
        // Move and zoom the viewport to make a certain area visible
        focus: ({ x, y, width, height, margin, minVisibleLength }) => {
            const viewport = viewportRef.current;
            console.log("Focus viewport:", x, y, width, height, margin, minVisibleLength);
            if (viewport && x && y) {
                // Ensure we have values to calculate with
                margin = margin ?? 0;
                width = width ?? 0;
                height = height ?? 0;
                minVisibleLength = minVisibleLength ?? 100;

                // Apply margin
                x = x - margin;
                y = y - margin;
                width = width + 2 * margin;
                height = height + 2 * margin;

                // Start with maximum zoom factor
                let zoom = Math.min(viewport.screenWidth / minVisibleLength, viewport.screenHeight / minVisibleLength)
                if (width > 0) {
                    // Reduce zoom to fit width
                    zoom = Math.min(zoom, viewport.screenWidth / width)
                }
                if (height > 0) {
                     // Reduce zoom to fit height
                     zoom = Math.min(zoom, viewport.screenHeight / height)
                }
                viewport.setZoom(zoom);
                viewport.moveCenter(x + width / 2, y + height / 2);

                return true;
            }

            return false;
        },
    }));

    return <PixiComponentViewport ref={viewportRef} app={app} {...props} />;
});

export default Viewport;