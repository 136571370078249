// Custom sorting function for natural sorting
export const naturalSort = (a, b) => {
    const regex = /(\d+)|(\D+)/g;
    const aParts = a.match(regex);
    const bParts = b.match(regex);

    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
        const aPart = aParts[i];
        const bPart = bParts[i];

        if (aPart === undefined) return -1;
        if (bPart === undefined) return 1;

        const aNum = parseInt(aPart, 10);
        const bNum = parseInt(bPart, 10);

        if (!isNaN(aNum) && !isNaN(bNum)) {
            // Compare numerically if both parts are numbers
            if (aNum !== bNum) return aNum - bNum;
        } else if (aPart !== bPart) {
            // Compare lexicographically if parts are not equal
            return aPart.localeCompare(bPart);
        }
    }
    return 0;
};