export const IS_RECORDING_COLOR = 0xff00ff;
export const IS_NOT_RECORDING_COLOR = 0xff0000;
export const RETURN_PATH_COLOR = 0x888888;
export const FINISHED_ALPHA = 0.6;
export const UNFINISHED_ALPHA = 1.0;


export const processCommand = (command, graphic, worldToMap, x, y, currentColor, currentAlpha, updateColorAndAlpha = true, xEnd = undefined, yEnd = undefined) => {
    if (updateColorAndAlpha) {
        currentAlpha = command.status === "succeeded" ? FINISHED_ALPHA : UNFINISHED_ALPHA;

        if (command.type === "SCHEDULE_SET_PAYLOAD_RECORDING") {
            currentColor = command.arguments.recording ? IS_RECORDING_COLOR : IS_NOT_RECORDING_COLOR;
        }
    }

    if (command.type === 'SCHEDULE_MOVE_XYZ' || command.type === 'SCHEDULE_FLY_TO_XY' || command.type === 'SCHEDULE_TAKEOFF') {
        let newX = xEnd ?? command.arguments.x;
        let newY = yEnd ?? command.arguments.y;

        if (x === undefined || y === undefined) {
            graphic.drawCircle(...Object.values(worldToMap({ x: newX, y: newY })), /*diameter=*/0.5);
        } else {
            drawFlightLine(graphic, worldToMap, x, y, newX, newY, currentColor, currentAlpha);
        }
        x = newX;
        y = newY;
    }

    if (command.type === 'SCHEDULE_MOVE_XYZ') {
        if (command.arguments.action === 'START_BURST') {
            currentColor = IS_RECORDING_COLOR;
        } else if (command.arguments.action === 'STOP_BURST') {
            currentColor = IS_NOT_RECORDING_COLOR;
        }
    }

    return { x, y, currentColor, currentAlpha };
}

export function isV3Task(droneCommands) {
    // return false;
    return droneCommands.some(command =>
        (command.type === "PLANNER_VERSION" || command.type === "SCHEDULE_PLANNER_VERSION") &&
        command.arguments?.version?.charAt(0) === "3"
    );
}

export const drawFlightLine = (graphic, worldToMap, startX, startY, endX, endY, color, alpha) => {
    graphic.moveTo(...Object.values(worldToMap({ x: startX, y: startY })));
    graphic.lineStyle(1, color, alpha);
    graphic.lineTo(...Object.values(worldToMap({ x: endX, y: endY })));
    graphic.drawCircle(...Object.values(worldToMap({ x: endX, y: endY })), /*diameter=*/0.5);

    const arrowSize = 0.2;
    const dx = endX - startX;
    const dy = endY - startY;
    const distance = Math.sqrt(dx * dx + dy * dy);
    const angle = Math.atan2(dy, dx);

    if (distance === 0) {
        return;
    }

    const arrowSpacing = 10; // Distance between arrows (10m)
    let arrowsToDraw = distance < arrowSpacing ? 1 : Math.floor(distance / arrowSpacing);

    for (let i = 1; i <= arrowsToDraw; i++) {
        let fraction = i / (arrowsToDraw + 1);

        // Calculate position for this arrow
        let arrowX = startX + dx * fraction;
        let arrowY = startY + dy * fraction;

        const baseCenterX = arrowX - arrowSize * Math.cos(angle);
        const baseCenterY = arrowY - arrowSize * Math.sin(angle);

        // Calculate the vertices of the triangle polygon for the arrow head
        const vertex1X = arrowX + arrowSize * Math.cos(angle);
        const vertex1Y = arrowY + arrowSize * Math.sin(angle);
        const vertex2X = baseCenterX + arrowSize * Math.cos(angle - Math.PI / 2);
        const vertex2Y = baseCenterY + arrowSize * Math.sin(angle - Math.PI / 2);
        const vertex3X = baseCenterX + arrowSize * Math.cos(angle + Math.PI / 2);
        const vertex3Y = baseCenterY + arrowSize * Math.sin(angle + Math.PI / 2);

        // Draw the triangle polygon
        graphic.beginFill(color, alpha);
        graphic.lineStyle(1, color, 0);
        graphic.moveTo(...Object.values(worldToMap({ x: vertex1X, y: vertex1Y })));
        graphic.lineTo(...Object.values(worldToMap({ x: vertex2X, y: vertex2Y })));
        graphic.lineTo(...Object.values(worldToMap({ x: vertex3X, y: vertex3Y })));
        graphic.lineTo(...Object.values(worldToMap({ x: vertex1X, y: vertex1Y })));
        graphic.endFill();
    }
};

export function replaceCommandsSettingsWithArguments(commands) {
    return commands.map(command => {
        if (command.settings) {
            command.arguments = command.settings;
            delete command.settings;
        }
        return command;
    });
}