import React from "react";
import InlineFormInput from "../../FormGroups/InlineFormInput";

export default function Edit({ command, commandIdx, areas, control, watch, errors }) {

    return (
        <>
            <InlineFormInput
                name={`commands.${commandIdx}.settings.speed`}
                control={control}
                defaultValue={command.settings?.speed || ""}
                errors={errors?.commands?.[commandIdx]?.settings?.speed}
                placeholder="speed"
                inputType="text"
                unit="m/s"
            />
        </>
    );
}
