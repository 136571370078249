import React from "react";
import { Text } from "@inlet/react-pixi";
import { useMapContext } from "../MapContext";

const locationTitleTextStyle = {
    align: 'center',
    fontSize: 100,
    fontWeight: 800,
    fill: '#d0d0d0',
    letterSpacing: 28
}

export const LocationTitle = React.memo(function LocationTitle({ name, x, y }) {
    const { worldToMap } = useMapContext();

    return (
        <Text
            text={name}
            style={locationTitleTextStyle}
            {...worldToMap({ x, y })}
        />
    )
});