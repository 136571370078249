import { commandType } from "./Attributes";

export const Skeleton = {
    type: commandType,
    settings: {
        x: "",
        y: "",
        z: "",
        velocity: "",
        yaw: "",
        action: "NO_ACTION",
        delay: ""
    },
};