import React from "react";
import { Container, Graphics, Text } from "@inlet/react-pixi";
import { useMapContext } from '../MapContext';

const markerTextStyle = {
    align: "center",
    fontWeight: "bold",
    fill: 'white',
    fontSize: 48,
    stroke: '#000000',
    strokeThickness: 3,
}

export const Marker = React.memo(function Marker({ label, x, y, width, rotation, valid }) {
    const { worldToMap } = useMapContext();


    function getAlpha(valid) {
        return valid === "1" ? 1.0 : 0.3;
    };

    const draw = React.useCallback(
        (graphic) => {
            const alpha = getAlpha(valid);
            const halfWidth = width * 0.5;
            const height = width;

            graphic.clear();
            graphic.beginFill(0x0000ff, alpha);
            graphic.lineStyle(0.15, 0x0000ff, alpha);
            graphic.drawPolygon([
                ...Object.values(worldToMap({ x: x - halfWidth, y: y - halfWidth })),
                ...Object.values(worldToMap({ x, y: y + height })),
                ...Object.values(worldToMap({ x: x + halfWidth, y: y - halfWidth })),
            ]);
            graphic.endFill();

            graphic.lineStyle(0.15, 0xff0000, alpha);
            graphic.beginFill(0xff0000, alpha);
            graphic.drawCircle(...Object.values(worldToMap({ x, y })), 1);
            graphic.endFill();

            const { x: mapX, y: mapY } = worldToMap({ x, y });
            graphic.setTransform(mapX, mapY, 1, 1, (Number(rotation - 90) % 360) * -Math.PI / 180, 0, 0, mapX, mapY);
        },
        [x, y, width, rotation, valid, worldToMap]
    );

    function getLabelOffset(rotation) {
        const adjustedRotation = ((Number(rotation) + 180) % 360) - 180;
        const sign = adjustedRotation <= 0 ? 1 : -1;
        const labelOffset = width * 0.5;
        return sign * labelOffset;
    }

    return (
        <Container>
            <Graphics draw={draw} />
            <Text
                text={label}
                anchor={0.5}
                {...worldToMap({ x, y: y + getLabelOffset(rotation) })}
                style={markerTextStyle}
                scale={0.125}
                alpha={getAlpha(valid)}
            />
        </Container>
    );
});