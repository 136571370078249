import React from "react";
import InlineFormInput from "../../FormGroups/InlineFormInput";

export default function Edit({ command, commandIdx, areas, control, watch, errors }) {

    return (
        <>
            <InlineFormInput
                name={`commands.${commandIdx}.settings.period`}
                control={control}
                defaultValue={command.settings?.period || ""}
                errors={errors?.commands?.[commandIdx]?.settings?.period}
                placeholder=""
                inputType="text"
                unit="s"
            />
        </>
    );
}
