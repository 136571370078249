import React from "react";
import { Graphics } from "@inlet/react-pixi";
import { useMapContext } from "../MapContext";

export const WorldBorder = React.memo(function WorldBorder({ x, y, width, height }) {
    const { worldToMap } = useMapContext();

    const draw = (graphic) => {
        graphic.clear();
        graphic.lineStyle(3, 0x000000, 1, 0.5);
        graphic.drawRect(...Object.values(worldToMap({ x, y, width, height })));
        graphic.endFill();
    };

    return (
        <Graphics draw={draw} />
    );
});