import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { Container, Stage } from "@inlet/react-pixi";
import Viewport from "./Viewport";
import * as api from "../../api/api";
import { MapProvider } from "./MapContext";
// import { XAxis } from "./mapElements/XAxis";
// import { YAxis } from "./mapElements/YAxis";
// import { ScrollBorder } from "./mapElements/ScrollBorder";
import { WorldBorder } from "./mapElements/WorldBorder";
import { LocationTitle } from "./mapElements/LocationTitle";
import { Area } from "./mapElements/Area";
import { Marker } from "./mapElements/Marker";
import { Measurement } from "./mapElements/Measurement";
import { Drone } from "./mapElements/Drone";
import { Dock } from "./mapElements/Dock";
import { Flyzone } from "./mapElements/Flyzone";
import { DroneCommands } from "./mapElements/DroneCommands";
import { ReturnPath } from "./mapElements/ReturnPath";
import { Point } from "./mapElements/Point.jsx";
import { replaceCommandsSettingsWithArguments } from "./utils/FlightPathUtils";
import { Alert } from "react-bootstrap";
import { useUser } from "../../contexts/user_provider";
import urls from "../../urls.js"

const worldMapScaleFactor = 10;
const worldMargin = 20; // Margin in meters between world and viewport boundary

// Conversion functions
function worldToMap({ mapHeight, ...rest }) {
    const mapCoordinates = {};

    // Iterate over all the provided properties
    for (const key in rest) {
        if (Object.hasOwnProperty.call(rest, key)) {
            const value = rest[key];

            if (key === 'y' && mapHeight !== undefined) {
                // Our y has origin at bottom left and the map has origin at top left
                // We need to compensate for that
                mapCoordinates.y = mapHeight - value * worldMapScaleFactor;
            } else {
                // Apply scaling for all other properties
                mapCoordinates[key] = value * worldMapScaleFactor;
            }
        }
    }

    // If y and height are both provided, we need to move y from 
    // the bottom left corner to the top left corner
    if ('y' in mapCoordinates && 'height' in mapCoordinates) {
        mapCoordinates.y = mapCoordinates.y - mapCoordinates.height;
    }

    return mapCoordinates;
}

export default function Map({
    organizationId,
    locationId,
    flightId,
    missionId,
    taskPreview, // taskId + droneId
    resumePreview, // missionId + droneId
    point, // x + y
}) {
    const mapRef = useRef(null);
    const viewportRef = useRef(null);
    const [frameDimensions, setFrameDimensions] = useState(null);
    const [worldDimensions, setWorldDimensions] = useState(null);
    const { getUserIsAdmin } = useUser();
    const isAdmin = useMemo(() => getUserIsAdmin(), [getUserIsAdmin]);
    const [disableAutoFocus, setDisableAutoFocus] = useState(false);

    // These components are always loaded
    const [location, setLocation] = useState();
    const [areas, setAreas] = useState({});
    const [flyzones, setFlyzones] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [docks, setDocks] = useState([]);
    const [droneConfigs, setDroneConfigs] = useState([]);
    const [droneFeedback, setDroneFeedback] = useState({});

    // These components are loaded based on inputs
    const [flight, setFlight] = useState();
    const [measurements, setMeasurements] = useState([]);
    const [taskPreviewCommands, setTaskPreviewCommands] = useState([]);
    const [resumePreviewCommands, setResumePreviewCommands] = useState([]);
    const [previewAlert, setPreviewAlert] = useState(null);

    // Handle resizing
    useEffect(() => {
        const updateDimensions = () => {
            if (mapRef.current) {
                const { width, height } = mapRef.current.getBoundingClientRect();
                setFrameDimensions({ width, height: height });
            }
        };

        // Set initial dimensions
        updateDimensions();

        // Add event listener to update dimensions on window resize
        window.addEventListener("resize", updateDimensions);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", updateDimensions);
        };
    }, [isAdmin, previewAlert]);

    // Set world dimensions
    useEffect(() => {
        // Generalized function to get max x and y values
        function getMaxValues(data, accessor) {
            return data.reduce(
                (acc, item) => {
                    const { x, y } = accessor(item);

                    return {
                        xMax: !isNaN(x) ? Math.max(acc.xMax, x) : acc.xMax,
                        yMax: !isNaN(y) ? Math.max(acc.yMax, y) : acc.yMax
                    };
                },
                { xMax: 0, yMax: 0 }
            );
        }

        // This defines the data as a list and how to get the max x and y from every item of that list
        const objectAccessors = [
            { data: Object.values(areas).flatMap(kap => Object.values(kap)), accessor: area => ({ x: parseFloat(area.max.x), y: parseFloat(area.max.y) }) },
            { data: markers?.markers || [], accessor: marker => ({ x: parseFloat(marker.x), y: parseFloat(marker.y) }) },
            { data: flyzones || [], accessor: flyzone => ({ x: parseFloat(flyzone.x_max), y: parseFloat(flyzone.y_max) }) }
            // Add more types here in the future as needed
        ];

        // Calculate max x and y values across all object types
        const totalMaxValues = objectAccessors.reduce(
            (acc, { data, accessor }) => {
                const { xMax, yMax } = getMaxValues(data, accessor);
                return {
                    xMax: Math.max(acc.xMax, xMax),
                    yMax: Math.max(acc.yMax, yMax)
                };
            },
            { xMax: 0, yMax: 0 }
        );

        // Set the world dimensions using the final max values
        setWorldDimensions({ width: totalMaxValues.xMax, height: totalMaxValues.yMax });
    }, [areas, flyzones, markers]);

    useEffect(() => {
        return api.subscribeLocation(organizationId, locationId, setLocation);
    }, [organizationId, locationId]);

    useEffect(() => {
        return api.subscribeMarkers(organizationId, locationId, setMarkers);
    }, [organizationId, locationId]);

    useEffect(() => {
        return api.subscribeAreas(organizationId, locationId, areas => {
            setAreas(areas);
            return areas;
        });
    }, [organizationId, locationId]);

    useEffect(() => {
        return api.subscribeFlyzones(organizationId, locationId, setFlyzones);
    }, [organizationId, locationId]);

    useEffect(() => {
        if (organizationId && locationId) {
            return api.subscribeDocks(organizationId, locationId, setDocks);
        }
    }, [organizationId, locationId]);

    useEffect(() => {
        return api.subscribeDroneConfigs(organizationId, locationId, setDroneConfigs);
    }, [organizationId, locationId]);

    useEffect(() => {
        return api.subscribeDronesFeedback(droneConfigs, setDroneFeedback);
    }, [droneConfigs]);

    const drones = useMemo(() => {
        return Object.keys(droneFeedback).map(droneId => {
            const droneData = droneFeedback[droneId];
            if (!droneData) return null;
            if (!droneData.widgets.position) return null;
            if (!droneData.widgets.orientation) return null;

            return {
                droneId,
                x: droneData.widgets.position.data.x,
                y: droneData.widgets.position.data.y,
                yaw: droneData.widgets.orientation.data.yaw,
                isOnline: droneData.isOnline !== undefined ? droneData.isOnline : true,
                bestReturnCommand: droneData.widgets?.bestReturnCommand?.data
            };
        }).filter(Boolean); // To filter out the nulls
    }, [droneFeedback]);

    // Subscribe to flightId if it is specified
    useEffect(() => {
        if (flightId) {
            return api.subscribeFlight(organizationId, locationId, flightId, (newFlight) => {
                if (newFlight && newFlight.commands) {
                    // Map over the commands and replace settings with arguments
                    const updatedCommands = replaceCommandsSettingsWithArguments(newFlight.commands);

                    // Update the flight object with the updated commands
                    setFlight({ ...newFlight, commands: updatedCommands });
                } else {
                    setFlight(newFlight);
                }
            });
        }
    }, [organizationId, locationId, flightId]);

    // Subscribe to measurements for mission
    useEffect(() => {
        if (missionId) {
            return api.subscribeMeasurements(
                organizationId,
                locationId,
                missionId,
                setMeasurements);
        }
    }, [organizationId, locationId, missionId]);

    useEffect(() => {
        if (taskPreview && taskPreview.droneId && taskPreview.taskId) {
            return api.getFlightPreview(taskPreview.droneId, taskPreview.taskId)
                .then(response => {
                    // It could be that response.data.data is a string "mission completed". We ignore that for now. Could be handled later
                    if (Array.isArray(response.data.data)) {
                        const transformedCommands = replaceCommandsSettingsWithArguments(response.data.data);
                        setTaskPreviewCommands(transformedCommands);
                    }
                    setPreviewAlert(null);
                }).catch((error) => {
                    setTaskPreviewCommands([])
                    if (error?.response?.status && [500, 520, 521, 522, 523].includes(error.response.status)) {
                        const errorMessage = error.response.status === 500 ? "Unexpected error" : error.response.data.message;
                        setPreviewAlert({ variant: 'danger', message: errorMessage })
                        console.log(errorMessage);
                    } else {
                        console.error(error);
                    }
                });
        }
    }, [organizationId, locationId, taskPreview, flyzones, areas, docks]);

    useEffect(() => {
        if (resumePreview && resumePreview.missionId && resumePreview.droneId) {
            return api.getMissionResumePreview(organizationId, locationId, resumePreview.missionId, resumePreview.droneId)
                .then(response => {
                    // It could be that response.data.data is a string "mission completed". We ignore that for now. Could be handled later
                    if (Array.isArray(response.data.data)) {
                        const transformedCommands = replaceCommandsSettingsWithArguments(response.data.data);
                        setResumePreviewCommands(transformedCommands);
                    }
                    setPreviewAlert(null);
                }).catch((error) => {
                    setResumePreviewCommands([])
                    if (error?.response?.status && [500, 520, 521, 522, 523].includes(error.response.status)) {
                        const errorMessage = error.response.status === 500 ? "Unexpected error" : error.response.data.message;
                        setPreviewAlert({ variant: 'danger', message: errorMessage })
                        console.log(errorMessage);
                    } else {
                        console.error(error);
                    }
                });
        }
    }, [organizationId, locationId, resumePreview, flyzones, areas, docks]);

    function getMeasurementsArea(measurements) {
        if (!measurements || measurements.length === 0) {
            return null; // Return null if the list is empty
        }

        let minX = Infinity;
        let maxX = -Infinity;
        let minY = Infinity;
        let maxY = -Infinity;

        for (const measurement of measurements) {
            const { x, y } = measurement.meta.position;

            if (x < minX) minX = x;
            if (x > maxX) maxX = x;
            if (y < minY) minY = y;
            if (y > maxY) maxY = y;
        }

        return {
            x: minX,
            y: minY,
            width: maxX - minX,
            height: maxY - minY,
        };
    }
    function getCommandsArea(commands) {
        if (!commands || commands.length === 0) {
            return null; // Return null if the list is empty
        }

        let minX = Infinity;
        let maxX = -Infinity;
        let minY = Infinity;
        let maxY = -Infinity;

        for (const command of commands) {
            if (command.type === 'SCHEDULE_MOVE_XYZ' || command.type === 'SCHEDULE_FLY_TO_XY' || command.type === 'SCHEDULE_TAKEOFF') {
                const { x, y } = command.arguments;

                if (x < minX) minX = x;
                if (x > maxX) maxX = x;
                if (y < minY) minY = y;
                if (y > maxY) maxY = y;
            }
        }

        return {
            x: minX,
            y: minY,
            width: maxX - minX,
            height: maxY - minY,
        };
    }

    const mapContextValue = useMemo(() => ({
        worldToMap: (coordinates) =>
            worldToMap({
                ...coordinates,
                mapHeight: (worldDimensions?.height ?? 0) * worldMapScaleFactor
            }),
    }), [worldDimensions?.height]);

    const focus = useCallback(({ x, y, width, height, margin }) => {
        if (viewportRef.current && !disableAutoFocus) {
            console.log(`Focussing on x: ${x}, y: ${y}, width: ${width}, height: ${height}, margin: ${margin}`);
            x = x + worldMargin;
            y = y - worldMargin; // For y we need to subtract because direction of y in world is flipped with respect to map
            return viewportRef.current.focus(
                mapContextValue.worldToMap({
                    x,
                    y,
                    width: width ?? null,
                    height: height ?? null,
                    margin: margin ?? null,
                    minVisibleLength: 10,
                })
            );
        }
    }, [viewportRef, mapContextValue, disableAutoFocus]);

    useEffect(() => {
        if (viewportRef.current && taskPreviewCommands && taskPreviewCommands.length > 0 && worldDimensions?.height) {
            focus({ ...getCommandsArea(taskPreviewCommands), margin: 5 })
        }
    }, [taskPreviewCommands, worldDimensions?.height, focus])

    useEffect(() => {
        if (viewportRef.current && resumePreviewCommands && resumePreviewCommands.length > 0 && worldDimensions?.height) {
            focus({ ...getCommandsArea(resumePreviewCommands), margin: 5 })
        }
    }, [resumePreviewCommands, worldDimensions?.height, focus])

    useEffect(() => {
        if (viewportRef.current && flight?.commands && flight?.commands.length > 0 && worldDimensions?.height) {
            focus({ ...getCommandsArea(flight?.commands), margin: 5 })
            // Don't refocus on flight commands update
            setDisableAutoFocus(true);
        }
    }, [flight?.commands, worldDimensions?.height, focus])

    useEffect(() => {
        if (viewportRef.current && measurements && measurements.length > 0 && worldDimensions?.height) {
            if (focus({ ...getMeasurementsArea(measurements), margin: 5 })) {
                // Disable auto focus such that we don't refocus when measurements are updated during uploading
                console.log("Disable auto focus");
                setDisableAutoFocus(true); 
            }
        }
    }, [measurements, worldDimensions?.height, focus])

    useEffect(() => {
        if (viewportRef.current && point && worldDimensions?.height) {
            if (focus({ ...point, margin: 10 })) {
                console.log("Disable auto focus");
                // Disable auto focus such that other components don't steal focus. 
                // Kind of relying on that point is known earlier than the rest. TODO: Improve
                setDisableAutoFocus(true);
            }
        }
    }, [point, worldDimensions?.height, focus])

    return (
        <>
            {isAdmin && previewAlert &&
                <Alert variant={previewAlert.variant} className="m-0">{previewAlert.message}</Alert>
            }
            <div
                ref={mapRef}
                style={{
                    width: '100%', // Use up the full space. Define the available space outside the map
                    height: '100%',
                    borderRadius: 'inherit', // Inherit borderRadius to look nice in Cards
                    overflow: 'hidden' // Hide overflow to look nice in Cards
                }}>
                {worldDimensions && frameDimensions &&
                    <Stage
                        width={frameDimensions.width}
                        height={frameDimensions.height}
                        options={{ backgroundColor: 0xeeeeee, antialias: true }}
                    >
                        <Viewport
                            ref={viewportRef}
                            screenWidth={frameDimensions.width}
                            screenHeight={frameDimensions.height}
                            worldHeight={(worldDimensions.height + 2 * worldMargin) * worldMapScaleFactor}
                            worldWidth={(worldDimensions.width + 2 * worldMargin) * worldMapScaleFactor}
                        >
                            <MapProvider value={mapContextValue}>
                                <Container position={[worldMargin * worldMapScaleFactor, worldMargin * worldMapScaleFactor]} >
                                    {/* <XAxis y={0} />
                                    <YAxis x={0} />
                                    <ScrollBorder
                                        x={-worldMargin}
                                        y={-worldMargin}
                                        width={worldDimensions.width + 2 * worldMargin}
                                        height={worldDimensions.height + 2 * worldMargin}
                                    /> */}
                                    <WorldBorder
                                        x={0}
                                        y={0}
                                        width={worldDimensions.width}
                                        height={worldDimensions.height}
                                    />
                                    <LocationTitle name={location?.name || ""} x={0} y={worldDimensions.height + 12.5} />
                                    {
                                        Object.keys(areas).map((areaKap) =>
                                            Object.keys(areas[areaKap]).map((areaPole) => (
                                                <Area
                                                    key={`${areaKap}_${areaPole}`}
                                                    label={`${areaKap}\n${areaPole}`}
                                                    x={areas[areaKap][areaPole].min.x}
                                                    y={areas[areaKap][areaPole].min.y}
                                                    width={(areas[areaKap][areaPole].max.x - areas[areaKap][areaPole].min.x)}
                                                    height={(areas[areaKap][areaPole].max.y - areas[areaKap][areaPole].min.y)}
                                                />
                                            ))
                                        )
                                    }
                                    {flyzones.map((flyzone) => (
                                        <Flyzone
                                            key={`${flyzone.name}_${flyzone.x_min}_${flyzone.x_max}_${flyzone.y_min}_${flyzone.y_max}`}
                                            label={flyzone.name}
                                            x={flyzone.x_min}
                                            y={flyzone.y_min}
                                            width={(flyzone.x_max - flyzone.x_min)}
                                            height={(flyzone.y_max - flyzone.y_min)}
                                        />
                                    ))}
                                    {
                                        markers?.markers?.map((marker, index) => (
                                            <Marker
                                                key={index}
                                                label={marker.id}
                                                x={Number(marker.x)}
                                                y={Number(marker.y)}
                                                width={Number(marker.width) / 100}
                                                rotation={Number(marker.yaw)}
                                                valid={marker.valid} />
                                        ))
                                    }
                                    {measurements.map((measurement) => (
                                        <Measurement
                                            key={measurement.id}
                                            x={measurement.meta.position.x}
                                            y={measurement.meta.position.y}
                                            url={urls.measurement(organizationId, locationId, missionId, measurement.id)}
                                            onClick={() => window.open(urls.measurement(organizationId, locationId, missionId, measurement.id), '_blank')}
                                        />
                                    ))}
                                    {docks.map((dock) => (
                                        <Dock
                                            key={dock.id}
                                            x={dock.position.x}
                                            y={dock.position.y}
                                            yaw={dock.yaw}
                                        />
                                    ))}
                                    {taskPreviewCommands && taskPreviewCommands.length > 0 &&
                                        <DroneCommands
                                            droneCommands={taskPreviewCommands}
                                        />
                                    }
                                    {resumePreviewCommands && resumePreviewCommands.length > 0 &&
                                        <DroneCommands
                                            droneCommands={resumePreviewCommands}
                                        />
                                    }
                                    {
                                        flight?.commands && flight?.commands.length > 0 && (
                                            <>
                                                <DroneCommands
                                                    droneCommands={flight.commands}
                                                    abortEvents={flight.events.filter((event) => { return event.type === "FLIGHT_ABORTED"; })}
                                                />
                                                <ReturnPath
                                                    drone={drones.find(drone => drone?.droneId === flight?.drone?.id)}
                                                    droneCommands={flight.commands}
                                                />
                                            </>
                                        )
                                    }
                                    {drones.filter(drone => !drone.isOnline) // First draw offline drones
                                        .map(({ droneId, x, y, yaw }) => (
                                            <Drone key={droneId} droneId={droneId} x={x} y={y} yaw={yaw} isOnline={false} />
                                        ))}

                                    {drones.filter(drone => drone.isOnline) // Then draw online drones
                                        .map(({ droneId, x, y, yaw }) => (
                                            <Drone key={droneId} droneId={droneId} x={x} y={y} yaw={yaw} isOnline={true} />
                                        ))}
                                    {point && point.x !== undefined && point.y !== undefined &&
                                        <Point
                                            x={point.x}
                                            y={point.y}
                                            color={0x00ffff}
                                            strokeColor={0x000000}
                                        />}
                                </Container>
                            </MapProvider>
                        </Viewport>
                    </Stage>
                }
            </div>
        </>
    );
}