import { FaFlask } from "react-icons/fa";
import { Badge } from "react-bootstrap";
import { STATE_CANCELLED, STATE_FAILED, STATE_RUNNING, STATE_SUCCEEDED, ProcessingState } from "../../api/missionApi";

export function AppInfoVariant(mission) {
    const state = ProcessingState(mission);
    switch (state) {
        case STATE_CANCELLED: return "secondary";
        case STATE_RUNNING: return "primary";
        case STATE_FAILED: return "danger";
        case STATE_SUCCEEDED: return "success";
        default: return "primary";
    }

}

export function AppInfoString(mission) {
    const doneStates = [STATE_CANCELLED, STATE_FAILED, STATE_SUCCEEDED]

    const appsToShow = mission?.apps?.filter((app) => app?.type !== "background") || [];
    const nrAppsToRun = appsToShow.length;
    const nrAppsDoneRunning = appsToShow.filter((app) => doneStates.includes(app.state))?.length;
    if (nrAppsToRun === nrAppsDoneRunning) {
        return (<>{nrAppsDoneRunning}</>);
    } else {
        return (<>{nrAppsDoneRunning + "/" + nrAppsToRun}</>);
    }
};

export default function AppInfoBadge(props) {
    return (
        <Badge pill bg={AppInfoVariant(props.mission)} className='me-1'>
            <FaFlask className='me-2' style={{ marginTop: "-2px" }} />
            {AppInfoString(props.mission)}
        </Badge>
    )
}