import { FaPlane } from "react-icons/fa";
import { Badge } from "react-bootstrap";
import { STATE_CANCELLED, STATE_FAILED, STATE_RUNNING, STATE_SUCCEEDED } from "../../api/missionApi";

export function FlightInfoVariant(mission) {
    const state = mission?.states?.collecting;
    switch (state) {
        case STATE_CANCELLED: return "secondary";
        case STATE_RUNNING: return "primary";
        case STATE_FAILED: return "danger";
        case STATE_SUCCEEDED: return "success";
        default: return "primary";
    }

}

export function FlightInfoString(mission) {
    const nrFlights = mission.flights.length;

    return (<>{nrFlights}</>);
};

export default function FlightInfoBadge(props) {
    return (
        <Badge pill bg={FlightInfoVariant(props.mission)} className='me-1'>
            <FaPlane className='me-2' style={{ marginTop: "-2px" }} />
            {FlightInfoString(props.mission)}
        </Badge>
    )
}