import { z } from 'zod';
import { commandType } from "./Attributes";

export const Schema = (areas) => z.object({
    type: z.literal(commandType),
    settings: z.object({
        startKap: z.string().min(1, "Enter a Bay"),
        startLeg: z.string().min(1, "Enter a Post"),
        endKap: z.string().min(1, "Enter a Bay"),
        endLeg: z.string().min(1, "Enter a Post"),
    }).refine((data) => {
        return (areas && (data.startKap in areas))
    }, {
        message: 'Bay does not exist',
        path: ['startKap'],
    }).refine((data) => {
        return (areas && (data.startKap in areas) && (data.startLeg in areas[data.startKap]))
    }, {
        message: 'Area does not exist',
        path: ['startLeg'],
    }).refine((data) => {
        return (areas && (data.endKap in areas))
    }, {
        message: 'Bay does not exist',
        path: ['endKap'],
    }).refine((data) => {
        return (areas && (data.endKap in areas) && (data.endLeg in areas[data.endKap]))
    }, {
        message: 'Area does not exist',
        path: ['endLeg'],
    })
});