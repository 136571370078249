import React, { createContext, useContext } from 'react';

const MapContext = createContext();

export const MapProvider = ({ value, children }) => (
  <MapContext.Provider value={value}>{children}</MapContext.Provider>
);

export const useMapContext = () => {
  return useContext(MapContext);
};
