import React from "react";
import { Container, Graphics } from "@inlet/react-pixi";
import { useMapContext } from '../MapContext';

export const Point = React.memo(function Point({ x, y, color, strokeColor }) {
    const { worldToMap } = useMapContext();

    const draw = React.useCallback(
        (graphic) => {
            graphic.clear();
            graphic.lineStyle(0.4, strokeColor ?? color);
            graphic.beginFill(color, 1)
            graphic.drawCircle(...Object.values(worldToMap({ x, y })), /*diameter=*/2);
            graphic.endFill()
        },
        [x, y, color, strokeColor, worldToMap]
    );

    return (
        <Container>
            <Graphics
                draw={draw}
            />
        </Container>
    );
});