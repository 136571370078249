import React from "react";
import { Container, Graphics } from "@inlet/react-pixi";
import { processCommand, isV3Task, RETURN_PATH_COLOR, UNFINISHED_ALPHA } from "../utils/FlightPathUtils";
import { useMapContext } from "../MapContext";


export const ReturnPath = React.memo(function ReturnPath({ drone, droneCommands }) {
    const { worldToMap } = useMapContext();

    const draw = React.useCallback(
        (graphic) => {
            graphic.clear();
            if (drone && Array.isArray(droneCommands) && droneCommands.length > 0 && isV3Task(droneCommands)) {
                const commandMap = new Map(droneCommands.map(command => [command.id, command]));

                let currentColor = RETURN_PATH_COLOR;
                let currentAlpha = UNFINISHED_ALPHA;
                let x = drone.x;
                let y = drone.y;

                graphic.lineStyle(1, currentColor, currentAlpha);

                let currentCommand = commandMap.get(drone.bestReturnCommand);
                while (currentCommand) {
                    ({ x, y, currentColor, currentAlpha } = processCommand(currentCommand, graphic, worldToMap, x, y, currentColor, currentAlpha, false));
                    const nextCommandId = currentCommand.next_command;
                    const nextCommand = nextCommandId ? commandMap.get(nextCommandId) : null;
                    if (nextCommand == null) {
                        const returnCommands = currentCommand.return_commands.map(commandId => commandMap.get(commandId));

                        if (returnCommands.length > 0) {
                            // Find the command with the shortest return duration
                            const shortestReturnCommand = returnCommands.reduce((shortest, current) => {
                                return (current.return_duration < shortest.return_duration) ? current : shortest;
                            });
                            currentCommand = shortestReturnCommand;

                            continue;
                        }
                        else {
                            currentCommand = null;
                        }
                    }
                    else {
                        currentCommand = nextCommand;
                    }
                }
            }
        },
        [drone, droneCommands, worldToMap]);

    return (
        <Container>
            <Graphics draw={draw} />
        </Container>
    );
});