import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useController } from 'react-hook-form';
import Select from 'react-select';

function InlineFormInput({ name, control, defaultValue, errors, placeholder, unit, inputType = 'text', options, onChange, field: externalField }) {
    
    // Always call useController to avoid violating React hooks rules
    const { field: internalField } = useController({ name, control, defaultValue });

    // Use the externalField if provided, otherwise use the internalField
    const field = externalField || internalField;

    const handleChange = (value) => {
        // Call the react-hook-form's onChange first
        field.onChange(value);

        // Call the custom onChange if provided
        if (onChange) {
            onChange(value);
        }
    };


    const renderInput = () => {
        if (inputType === 'select') {
            return (
                <Select
                    value={options.find(option => option.value === field.value) || ""}
                    onChange={(selectedOption) => handleChange(selectedOption ? selectedOption.value : "")}
                    options={options}
                    placeholder={placeholder}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            minWidth: '100px'
                        }),
                    }}
                />
            );
        } else {
            return (
                <>
                    <Form.Control
                        type={inputType}
                        placeholder={placeholder}
                        {...field}
                        onChange={(e) => handleChange(e.target.value)}
                        isInvalid={!!errors}
                        htmlSize={Math.max(String(field.value).length, 2)}
                    />
                    {unit && <InputGroup.Text>{unit}</InputGroup.Text>}
                </>
            );
        }
    };

    return (
        <Form.Group controlId={name} className="me-2">
            <InputGroup>
                {renderInput()}
            </InputGroup>
            {errors && (
                <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.message}
                </Form.Control.Feedback>
            )}
        </Form.Group>
    );
}

export default InlineFormInput;
