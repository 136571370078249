import React from "react";

export default function Edit({ command, commandIdx, areas, control, watch, errors }) {

    return (
        <>
            
        </>
    );
}
