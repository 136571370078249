import React from "react";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import * as api from "../../api/api";

export default function AppSelector(props) {
    const [apps, setApps] = React.useState([]);
    const [value, setValue] = React.useState(props.value || 'none');
    const { organizationId, locationId } = useParams();

    React.useEffect(() => {
        api.getLocationApps(organizationId, locationId, setApps);
    }, [organizationId, locationId]);

    return (
        <Form.Select
            value={value}
            onChange={(event) => { setValue(event.target.value); props.onChange(event.target.value); }}
        >
            <option disabled value="none">
                Select an app ...
            </option>
            {apps.map((app) => {
                return (
                    <option
                        key={app.id}
                        value={app.id}
                    >
                        {app.name}
                    </option>
                );
            })}
        </Form.Select>
    );
}
