import React from "react";
import { Container, Graphics } from "@inlet/react-pixi";
import { useMapContext } from '../MapContext';

export const Flyzone = React.memo(function Flyzone({ x, y, width, height }) {
    const { worldToMap } = useMapContext();

    const draw = React.useCallback(
        (graphic) => {
            graphic.clear();
            graphic.beginFill(0x00ff00, 0.15);
            graphic.drawRect(...Object.values(worldToMap({ x, y, width, height })));
            graphic.endFill();
        },
        [x, y, width, height, worldToMap]
    );

    return (
        <Container>
            <Graphics draw={draw} />
        </Container>
    );
});