import { label } from "./Attributes";

export default function Preview({ command }) {
    return (
        <span className="m-3">
            <span className="me-2">{label}</span>
            <code className="me-2">
                x={command.settings.x || 0}{' '}
                y={command.settings.y || 0}{' '}
                z={command.settings.z || 0}{' '}
                speed={command.settings.velocity || 0}{' '}
                yaw={command.settings.yaw || 0}{' '}
                delay={command.settings.delay || 0}{' '}
                action={command.settings.action || 0}
            </code>
        </span>

    );
}