import queryString from 'query-string';
import React from "react";
import { Alert, Button, ButtonGroup, Card, Dropdown, Form, InputGroup, Modal } from "react-bootstrap";
import { FaExclamationCircle, FaPlay } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import BackButton from "../../components/BackButton";
import CorvusSetting from "../../components/CorvusSetting";
import DroneSelector from "../../components/DroneSelector";
import Layout, { StartBreadcrumbContainer, Title } from "../../components/Layout/Layout";
import TaskSelector from "../../components/TaskSelector";
import Map from '../../components/Map/index.jsx';
import urls from "../../urls.js";

export default function StartPage(props) {
    //const { getUserIsAdmin } = useUser();
    const { droneId, taskId } = queryString.parse(props.location.search);
    const { organizationId, locationId } = useParams();
    const [task, setTask] = React.useState(null);
    const history = useHistory();
    const [startModalVisible, setStartModalVisible] = React.useState(false);

    function hideStartModal() {
        setStartModalVisible(false);
    }

    function showStartModal() {
        setStartModalVisible(true);
    }

    function onTaskChange(newTaskId) {
        let startUrl = `${urls.locationStart(organizationId, locationId)}?`;
        startUrl += `taskId=${newTaskId || 'none'}`;
        startUrl += `&droneId=${droneId || 'none'}`;
        history.push(startUrl);
    }

    function onDroneChange(newDroneId) {
        let startUrl = `${urls.locationStart(organizationId, locationId)}?`;
        startUrl += `taskId=${taskId || 'none'}`;
        startUrl += `&droneId=${newDroneId || 'none'}`;
        history.push(startUrl);
    }
    /*
    function startTaskBeta() {
        const promise = api.postFlightBeta(droneId, task, locationId)
        hideStartModal();
        history.push(urls.drone(droneId));
        return promise;
    }
    */

    function startTask() {
        const promise = api.startMissionHandler(organizationId, locationId, taskId, droneId)
        hideStartModal();
        history.push(urls.drone(droneId));
        return promise;
    }

    React.useEffect(() => {
        if (taskId) {
            return api.subscribeTask(organizationId, locationId, taskId, setTask);
        }
    }, [organizationId, locationId, taskId]);

    function shouldDisableStartButton() {
        if (!taskId || taskId === 'none') {
            return true;
        }
        if ((task && !task.commands) || (task && task.commands && !task.commands.length)) {
            return true;
        }
        if (!droneId || droneId === 'none') {
            return true;
        }
        if (task?.commands?.length === 0) {
            return true;
        }
        return false;
    }

    return (
        <Layout>
            <StartBreadcrumbContainer />
            <Modal show={startModalVisible} onHide={hideStartModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Start</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Start{' '}task{' '}<b>{task?.name}</b>{' '}on{" "}Drone{' '}
                    <b>{droneId}</b>{' '}now?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="outline-secondary"
                        onClick={hideStartModal}
                    >
                        Cancel
                    </Button>
                    <Dropdown as={ButtonGroup}
                        align={{ lg: 'start' }}
                        disabled={shouldDisableStartButton()}
                    >
                        <Button
                            variant="primary"
                            onClick={startTask}
                            disabled={shouldDisableStartButton()}
                        >
                            <FaPlay
                                className="me-2"
                                style={{ marginTop: "-2px" }}
                            />
                            Start
                        </Button>
                        {/* {getUserIsAdmin() && (
                            <>
                                <Dropdown.Toggle
                                    split
                                    variant="primary"
                                    disabled={shouldDisableStartButton()}
                                />
                                <Dropdown.Menu
                                >
                                    <Dropdown.Item
                                        onClick={startTaskBeta}
                                    >
                                        <FaFlask
                                            className="me-2"
                                            style={{ marginTop: "-2px" }}
                                        />
                                        Start Beta
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </>
                        )} */}
                    </Dropdown>
                </Modal.Footer>
            </Modal>
            <Card>
                <Title title={
                    <>
                        <BackButton href={urls.location(organizationId, locationId)} />
                        Start
                    </>
                } />
                <Card.Body className="px-4 pt-0">
                    {(task?.commands?.length === 0) && (
                        <Alert className="m-0 mb-3" variant="danger">
                            <FaExclamationCircle
                                className={`me-2 text-danger`}
                            />
                            This task has no drone commands and cannot be started.
                        </Alert>
                    )}
                    {(task?.apps?.length === 0) && (
                        <Alert className="m-0 mb-3" variant="warning">
                            <FaExclamationCircle
                                className={`me-2 text-warning`}
                            />
                            Be aware that this task does not contain any apps. Collected data will not be processed.
                        </Alert>
                    )}
                    <Form>
                        <Form.Group>
                            <InputGroup>
                                <CorvusSetting name="Drone" className="mb-2">
                                    <DroneSelector
                                        value={droneId || 'none'}
                                        onChange={onDroneChange}
                                    />
                                </CorvusSetting>
                                <CorvusSetting name="Task" className="mb-3">
                                    <TaskSelector
                                        value={taskId || 'none'}
                                        onChange={onTaskChange}
                                    />
                                </CorvusSetting>
                            </InputGroup>
                            <Button
                                className='me-2'
                                variant="primary"
                                onClick={showStartModal}
                                disabled={shouldDisableStartButton()}
                            >
                                <FaPlay
                                    className="me-2"
                                    style={{ marginTop: "-2px" }}
                                />
                                Start
                            </Button>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
            <Card className='mt-4' style={{ height: '50vh' }}>
                <Map
                    organizationId={organizationId}
                    locationId={locationId}
                    taskPreview={{ taskId, droneId }}
                />
            </Card>
        </Layout>
    );
}
