import React from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Layout, { TaskAppSettingsBreadcrumbContainer, Title } from "../../components/Layout/Layout";
import SampleSeedlingsSettings from "../../components/AppSettings/SampleSeedlings";


export default function TaskAppSettingsPage(props) {
    const { organizationId, locationId, taskId, appId } = useParams();
    console.log("appId", appId);

    return (
        <Layout>
            <TaskAppSettingsBreadcrumbContainer />
            <Card>
                <Title title={`App Settings`} />
                {(appId === "WfJUnkRP6wx0C7kZDKFn" || appId === "4lzObdrZZzbPumJ9nAfa") && (
                    <SampleSeedlingsSettings
                        organizationId={organizationId}
                        locationId={locationId}
                        taskId={taskId}
                        appId={appId}>
                    </SampleSeedlingsSettings>)
                }
            </Card>
        </Layout>
    );
}
