import { z } from 'zod';

// Use better message. The default message is 'Expected number, received nan' but that is strange because it comes from implementation details to convert text to number
export const numberFromString = z.coerce.number({ invalid_type_error: "Enter a valid number" });

// Define a schema that accepts a comma-separated string, splits it into an array, and validates each element as a string
export const commaSeparatedStringOrArraySchema = z.union([
    z.string(),
    z.array(z.string())
]).transform((input) => {
    // If the input is a string and it's empty, return an empty array
    if (typeof input === 'string') {
        if (input === '') {
            return [];
        }
        // If the input is a string (not empty), split by commas and trim each item
        return input.split(',').map(item => item.trim());
    }
    // If the input is already an array, return it directly
    if (Array.isArray(input)) {
        return input;
    }
});

// This is similar to numberFromString but it doesn't convert an empty string to 0. 
// It converts it to undefined and also accepts undefined 
export const toOptionalNumber = z
    .union([z.string(), z.number()])
    .transform((value) => {
        if (typeof value === 'string' && value.trim() === '') {
            return undefined; // Convert empty string to undefined
        }
        return value;
    })
    .refine((value) => value === undefined || !isNaN(Number(value)), {
        message: 'Enter a valid number',
    })
    .transform((value) => (value === undefined ? undefined : Number(value)));

// Schema to convert valid strings or numbers to number, disallowing undefined
export const toNumber = toOptionalNumber.refine((value) => value !== undefined, {
    message: 'Enter a valid number',
});

export const positiveNumber = toNumber.refine((value) => value >= 0, {
    message: 'Enter a positive number',
});

export const nonNegativeNumber = toNumber.refine((value) => value > 0, {
    message: 'Enter a number greater than 0',
});
