export default function Preview({ command }) {
    return (
        <span className="m-3">
            <span className="me-2">Wait for</span>
            <code className="me-2">
                {command.settings.period || 0}
            </code>{'seconds'}
        </span>

    );
}