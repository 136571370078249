import React from "react";
import { Button, Modal } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";

import { useUser } from "../../contexts/user_provider";
import AppSelector from "../AppSelector";
import * as api from "../../api/api";

export default function AddAppModal(props) {
    const { getUserIsAdmin } = useUser();

    const organizationId = props.organizationId;
    const locationId = props.locationId;
    const mission = props.mission;

    const [selectedApp, setSelectedApp] = React.useState(null);

    async function addApp() {
        console.log("selected app", selectedApp);
        console.log(mission.apps.filter((app) => app.id === selectedApp).length);
        if (mission && mission.apps.filter((app) => app.id === selectedApp).length === 0) {
            api.addAppToMission(organizationId, locationId, mission.id, selectedApp);
        }
        props.hide();
    }

    return (getUserIsAdmin() &&
        <Modal show={props.show} onHide={() => props.hide()}>
            <Modal.Header closeButton>
                <Modal.Title>Add app</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AppSelector
                    onChange={(newApp) => setSelectedApp(newApp)}>
                </AppSelector>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="outline-secondary"
                    onClick={() => props.hide()}
                >
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    onClick={addApp}
                >
                    <FaPlus
                        className="me-2"
                        style={{ marginTop: "-2px" }}
                    />
                    Add
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
