import React from "react";
import { Badge, Tooltip, OverlayTrigger } from "react-bootstrap";
import * as missionApi from "../../api/missionApi";

export default function MissionActivityBadge(props) {
    const missionState = missionApi.getMissionState(props.mission)
    const missionActivity = missionApi.getMissionActivity(props.mission);
    const alerts = props.mission.mission_handler?.alerts;
    const lastAlert = alerts ? alerts[alerts.length - 1] : null;
    const missionId = props.mission.id;

    const renderTooltip = (props) => (
        <Tooltip id={`overlay-tooltip-${missionId}`} {...props}>
            <div>
                {lastAlert && (
                    <span dangerouslySetInnerHTML={{ __html: lastAlert.message }} />
                )}
            </div>
        </Tooltip>
    );

    function getColor() {
        switch (missionState) {
            case missionApi.STATE_PENDING:
            case missionApi.STATE_CANCELLED:
            case 'waiting':
                return 'secondary';
            case missionApi.STATE_SUCCEEDED:
            case 'finished':
                return 'success';
            case missionApi.STATE_FAILED:
                return 'danger';
            default:
                return 'primary'
        }
    }

    const handleBadgeClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const badgeComponent = (
        <Badge pill bg={getColor()} className={props.className} onClick={handleBadgeClick}>
            {missionActivity}
        </Badge>
    );

    return (
        <>
            {alerts ? (
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 250 }}
                    overlay={renderTooltip}
                >
                    {badgeComponent}
                </OverlayTrigger>
            ) : (
                badgeComponent
            )}
        </>
    );
}
