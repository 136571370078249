import { z } from 'zod';
import { toNumber } from '../../../utils/zodUtils';
import { commandType } from "./Attributes";

export const Schema = () => z.object({
    type: z.literal(commandType),
    settings: z.object({
        x: toNumber,
        y: toNumber,
        z: toNumber,
        velocity: toNumber,
        yaw: toNumber,
        action: z.enum(['NO_ACTION', 'START_BURST', 'STOP_BURST']),
        delay: toNumber
    })
});