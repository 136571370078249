import React from "react";
import { Sprite } from "@inlet/react-pixi";
import { useMapContext } from '../MapContext';

export const Dock = React.memo(function Dock({ x, y, yaw }) {
    const { worldToMap } = useMapContext();

    return (
        <Sprite
            image={process.env.PUBLIC_URL + "/dock.png"}
            scale={{ x: 0.015, y: 0.015 }}
            anchor={0.5}
            angle={-yaw}
            {...worldToMap({ x, y })}
        />
    );
});