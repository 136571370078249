import { z } from 'zod';
import { toNumber } from '../../../utils/zodUtils';
import { commandType } from "./Attributes";

export const Schema = () => z.object({
    type: z.literal(commandType),
    settings: z.object({
        x: toNumber,
        y: toNumber,
    })
});