import { label } from "./Attributes";

export default function Preview({ command }) {
    return (
        <span className="m-3">
            <span className="me-2">{label}</span>
            <span className="me-2">xyz</span>
            <code className="me-2">
                {command.settings.x || ''}m, {command.settings.y || ''}m, {command.settings.z || ''}m
            </code>
            <span className="me-2">yaw</span>
            <code className="me-2">
                {command.settings.yaw ? command.settings.yaw + '°' : 'flyzone'}
            </code>
        </span>
    );
}