import React from "react";
import InlineFormInput from "../../FormGroups/InlineFormInput";

export default function Edit({ command, commandIdx, areas, control, watch, errors }) {

    return (
        <>
            <span className="my-2 me-2">to</span>
            <InlineFormInput
                name={`commands.${commandIdx}.settings.picture_distance`}
                control={control}
                defaultValue={command.settings?.picture_distance || ""}
                errors={errors?.commands?.[commandIdx]?.settings?.picture_distance}
                placeholder=""
                inputType="text"
                unit="m"
            />
        </>
    );
}
