import React from "react";
import InlineFormInput from "../../FormGroups/InlineFormInput";

export default function Edit({ command, commandIdx, areas, control, watch, errors }) {
    const recordingOptions = [
        { value: true, label: "enabled" },
        { value: false, label: "disabled" }
    ]

    return (
        <>
            <InlineFormInput
                name={`commands.${commandIdx}.settings.recording`}
                control={control}
                defaultValue={command.settings?.recording || ""}
                errors={errors?.commands?.[commandIdx]?.settings?.recording}
                inputType="select"
                options={recordingOptions}
            />
        </>
    );
}
