const commandFolderList = [
    'CommandScanArea',
    'CommandFlyToWaypoint',
    'CommandWaitForPeriod',
    'CommandTakePicture',
    'CommandSetPayloadRecording',
    'CommandSetPictureDistance',
    'CommandSetSpeedXY',
    'CommandMoveXYZ',
    'CommandFlyToXY',
    'CommandFlyToZ',
    'CommandFlyToYaw',
];

export const commandInfo = commandFolderList.reduce((acc, commandName) => {
    const commandModule = require(`./${commandName}`); // Import the command from the folder
    const commandType = commandModule.commandType;

    // Add the exported commands from the folder to the map using the commandType as key
    return {
        ...acc,
        [commandType]: {
            ...commandModule,
        },
    };
}, {});


export function canEditTaskWithCommands(commands, userIsAdmin) {
    return commands.every(command => {
        const commandType = command.type;
        const commandInfoItem = commandInfo[commandType];

        // If command is admin-only, ensure the user is admin
        if (commandInfoItem?.isAdminOnly && !userIsAdmin) {
            return false; // Task cannot be edited
        }
        return true; // Command is either not admin-only, or the user is admin
    });
}