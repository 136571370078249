import { commandType } from "./Attributes";

export const Skeleton = {
    type: commandType,
    settings: {
        x: "",
        y: "",
        z: "",
        yaw: "",
        useFlyzoneYaw: true, // This shouldn't be stored in the database. Remove it before saving it
    },
};