import React from "react";
import InlineFormInput from "../../FormGroups/InlineFormInput";

export default function Edit({ command, commandIdx, areas, control, watch, errors }) {

    return (
        <>
            <InlineFormInput
                name={`commands.${commandIdx}.settings.z`}
                control={control}
                defaultValue={command.settings?.z || ""}
                errors={errors?.commands?.[commandIdx]?.settings?.z}
                placeholder="z"
                inputType="text"
                unit="m"
            />
        </>
    );
}
