import React from "react";
import InlineFormInput from "../../FormGroups/InlineFormInput";

export default function Edit({ command, commandIdx, areas, control, watch, errors }) {

    const actionOptions = [
        { value: 'NO_ACTION', label: 'No action' },
        { value: 'START_BURST', label: 'Start burst' },
        { value: 'STOP_BURST', label: 'Stop burst' },
    ];

    return (
        <>
            <InlineFormInput
                name={`commands.${commandIdx}.settings.x`}
                control={control}
                defaultValue={command.settings?.x || ""}
                errors={errors?.commands?.[commandIdx]?.settings?.x}
                placeholder="x"
                inputType="text"
                unit="m"
            />

            <InlineFormInput
                name={`commands.${commandIdx}.settings.y`}
                control={control}
                defaultValue={command.settings?.y || ""}
                errors={errors?.commands?.[commandIdx]?.settings?.y}
                placeholder="y"
                inputType="text"
                unit="m"
            />

            <InlineFormInput
                name={`commands.${commandIdx}.settings.z`}
                control={control}
                defaultValue={command.settings?.z || ""}
                errors={errors?.commands?.[commandIdx]?.settings?.z}
                placeholder="z"
                inputType="text"
                unit="m"
            />

            <span className="my-2 mx-1">yaw</span>
            <InlineFormInput
                name={`commands.${commandIdx}.settings.yaw`}
                control={control}
                defaultValue={command.settings?.yaw || ""}
                errors={errors?.commands?.[commandIdx]?.settings?.yaw}
                placeholder="yaw"
                inputType="text"
                unit="°"
            />

            <span className="my-2 mx-1">velocity</span>
            <InlineFormInput
                name={`commands.${commandIdx}.settings.velocity`}
                control={control}
                defaultValue={command.settings?.velocity || ""}
                errors={errors?.commands?.[commandIdx]?.settings?.velocity}
                placeholder="velocity"
                inputType="text"
                unit="m/s"
            />

            <span className="my-2 mx-1">delay</span>
            <InlineFormInput
                name={`commands.${commandIdx}.settings.delay`}
                control={control}
                defaultValue={command.settings?.delay || ""}
                errors={errors?.commands?.[commandIdx]?.settings?.delay}
                placeholder="delay"
                inputType="text"
                unit="s"
            />

            <span className="my-2 mx-1">action</span>
            <InlineFormInput
                name={`commands.${commandIdx}.settings.action`}
                control={control}
                defaultValue={command.settings?.action || ""}
                errors={errors?.commands?.[commandIdx]?.settings?.action}
                placeholder="action"
                inputType="select"
                options={actionOptions}
            />

        </>
    );
}
