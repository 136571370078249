export default function Preview({ command }) {
    return (
        <span className="m-3">
            {command.settings.recording ?
                <span className='text-success'>Enable</span>
                :
                <span className='text-danger'>Disable</span>
            }{' payload'}
        </span>
    );
}