import { label } from "./Attributes";

export default function Preview({ command }) {
    return (
        <span className="m-3">
            <span className="me-2">{label}</span>
            <code className="me-2">
                {command.settings.x || 0}{'m, '}
                {command.settings.y || 0}{'m'}
            </code>
        </span>

    );
}