import { z } from 'zod';
import { toNumber } from '../../../utils/zodUtils';
import { commandType } from "./Attributes";

export const Schema = () => z.object({
    type: z.literal(commandType),
    settings: z.object({
        x: toNumber,
        y: toNumber,
        z: toNumber,
        useFlyzoneYaw: z.boolean(),
        yaw: z.union([toNumber, z.literal('')]), // Allow either a number or an empty string initially
    }).refine((data) => {
        if (data.useFlyzoneYaw) {
            return data.yaw === ''; // When useFlyzoneYaw is true, yaw must be an empty string
        } else {
            return typeof data.yaw === 'number' && !isNaN(data.yaw); // When useFlyzoneYaw is false, yaw must be a valid number
        }
    }, {
        message: 'Enter a yaw',
        path: ['yaw'],
    })
});