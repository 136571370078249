import { FaImage } from "react-icons/fa";
import { Badge } from "react-bootstrap";

import { uploadStatusIsTodo } from "../../components/UploadStatusMessage";
import { STATE_CANCELLED, STATE_FAILED, STATE_PENDING, STATE_RUNNING, STATE_SUCCEEDED } from '../../api/missionApi.jsx';
import useExpectedImages from '../../hooks/useExpectedImages.jsx';
import useMissionFlightsSubscription from '../../hooks/useMissionFlightsSubscription.jsx';

export function MeasurementInfoString(mission) {
    const flights = useMissionFlightsSubscription(mission.organizationId, mission.locationId, mission)
    const expectedImages = useExpectedImages(flights);

    const nr_uploaded_measurements = mission?.measurements?.length || 0;;

    if (expectedImages === undefined) {
        return nr_uploaded_measurements;
    } else {
        const inCollectingState = mission?.states?.collecting !== undefined
            ? (mission.states.collecting === STATE_RUNNING || mission.states.collecting === STATE_PENDING)
            : uploadStatusIsTodo(mission);

        let displayValue = '';
        if ((nr_uploaded_measurements > 0 || !inCollectingState) &&
            nr_uploaded_measurements !== expectedImages) {

            displayValue += nr_uploaded_measurements + '/'
        }
        displayValue += expectedImages
        return displayValue;
    }
};

export function MeasurementInfoVariant(mission) {
    const state = mission?.states?.uploading;
    switch (state) {
        case STATE_CANCELLED: return "secondary";
        case STATE_RUNNING: return "primary";
        case STATE_FAILED: return "danger";
        case STATE_SUCCEEDED: return "success";
        default: return "primary";
    }

}

export default function UploadInfoBadge(props) {
    return (
        <Badge pill bg={MeasurementInfoVariant(props.mission)} className='me-1'>
            <FaImage className='me-2' style={{ marginTop: "-2px" }} />
            {MeasurementInfoString(props.mission)}
        </Badge>
    )
}