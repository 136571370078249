import React from "react";
import InlineFormInput from "../../FormGroups/InlineFormInput";

export default function Edit({ command, commandIdx, areas, control, watch, errors }) {

    return (
        <>
            <InlineFormInput
                name={`commands.${commandIdx}.settings.yaw`}
                control={control}
                defaultValue={command.settings?.yaw || ""}
                errors={errors?.commands?.[commandIdx]?.settings?.yaw}
                placeholder="yaw"
                inputType="text"
                unit="°"
            />
        </>
    );
}
